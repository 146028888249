import React from 'react';
import {injectIntl} from 'react-intl';
import classNames from 'classnames';
import {YoloValue, NopeValue, MaxValue} from '../data/constants';



const hint = (value) => {
	switch (value) {
		case MaxValue:
			return '+';
		case NopeValue:
			return '-';
		default:
			return '~';
	}
};

const nextValue = (value) => {
	switch (value) {
		case MaxValue:
			return NopeValue;
		case NopeValue:
			return YoloValue;
		default:
			return MaxValue;

	}
};

const AttributeInput = ({label, value = YoloValue, onChange, intl}) => (
	<button
		type="button"
		className={classNames({
			AttributeInput: true,
			'AttributeInput--nope': value === NopeValue,
			'AttributeInput--max': value === MaxValue
		})}
		title={
			intl.formatMessage({
				id: 'AttributeInput.title'
			}, {
				label,
				value
			})
		}
		onClick={() => {
			onChange(nextValue(value));
		}}
	>
		<span class="AttributeInput-hint">
			{hint(value)}
		</span>
		{` ${label}`}
	</button>
);

export default injectIntl(AttributeInput);
