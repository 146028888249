import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {selectCurrentPage, selectPageCount} from '../app/selectors';
import {setPage} from '../app/actions';



const PaginationButton = ({isActive, onClick, children, ...props}) => (
	<button
		{...props}
		className={classNames({
			Button: true,
			'Button--selected': isActive
		})}
		aria-current={isActive ? 'page' : null}
		onClick={onClick}
	>
		{children}
	</button>
);

const Pagination = ({page, total, onChangePage}) => {
	const isFirst = page <= 0;
	const isLast = page >= (total - 1);

	return (
		<div className="Pagination">
			<div className="ButtonList">
				<PaginationButton
					onClick={onChangePage.bind(null, 0)}
					disabled={isFirst}
				>
					<FontAwesomeIcon icon="fast-backward" />
				</PaginationButton>

				<PaginationButton
					onClick={onChangePage.bind(null, page - 1)}
					disabled={isFirst}
				>
					<FontAwesomeIcon icon="step-backward" />
				</PaginationButton>

				<input
					className="Pagination-input"
					type="number"
					min={1}
					max={total}
					value={page + 1}
					onChange={(event) => {
						onChangePage(event.target.value - 1);
					}}
				/>

				<span className="Pagination-total">
					/ {total}
				</span>

				<PaginationButton
					onClick={onChangePage.bind(null, page + 1)}
					disabled={isLast}
				>
					<FontAwesomeIcon icon="step-forward" />
				</PaginationButton>

				<PaginationButton
					onClick={onChangePage.bind(null, total - 1)}
					disabled={isLast}
				>
					<FontAwesomeIcon icon="fast-forward" />
				</PaginationButton>
			</div>
		</div>
	);
};

export default connect(
	(state) => ({
		page: selectCurrentPage(state),
		total: selectPageCount(state)
	}),
	(dispatch) => ({
		onChangePage(page) {
			dispatch(setPage(page));
		}
	})
)(Pagination);
