import {
	SET_IS_REFRESHING,
	SET_SELECTED_TRACK_IDS,
	SET_LAST_SELECTED_TRACK_ID,
	TOGGLE_EDIT_MODE
} from './actions';



const initialState = {
	isRefreshing: false,
	isEditMode: false,
	selectedTrackIds: [],
	lastSelectedTrackId: null
};

export default (state = initialState, {type, payload}) => {
	switch (type) {
		case SET_IS_REFRESHING:
			return {
				...state,
				isRefreshing: payload
			};

		case SET_SELECTED_TRACK_IDS:
			return {
				...state,
				selectedTrackIds: state.isEditMode
					? payload
					: initialState.selectedTrackIds
			};

		case SET_LAST_SELECTED_TRACK_ID:
			return {
				...state,
				lastSelectedTrackId: state.isEditMode
					? payload
					: initialState.lastSelectedTrackId
			};

		case TOGGLE_EDIT_MODE:
			return {
				...state,
				isEditMode: !state.isEditMode,
				selectedTrackIds: initialState.selectedTrackIds,
				lastSelectedTrackId: initialState.lastSelectedTrackId
			};

		default:
			return state;
	}
};
