import {NopeValue, YoloValue, MaxValue} from '../data/constants';
import {Attributes} from '../data/attributes';
import {Players} from '../app/constants';



export default {
	'PlayerSelectorModal.title': 'Which player do you want to use ?',
	'PlayerSelectorModal.deezerHint': 'Requires an account',
	'PlayerSelectorModal.spotifyHint': 'Requires a premium account',
	'PlayerSelectorModal.youTubeHint': 'May not play the exact titles',

	'Player.durations': '{elapsedTime, time, short}/{totalTime, time, short}',

	[`Players.${Players.deezer}`]: 'Deezer',
	[`Players.${Players.youTube}`]: 'YouTube',

	[`Attributes.${Attributes.valence}`]: 'Happiness',
	[`Attributes.${Attributes.energy}`]: 'Energy',
	[`Attributes.${Attributes.danceability}`]: 'Danceability',
	[`Attributes.${Attributes.blues}`]: 'Blues',
	[`Attributes.${Attributes.dub}`]: 'Dub',
	[`Attributes.${Attributes.dubstep}`]: 'Dubstep',
	[`Attributes.${Attributes.drumAndBass}`]: 'Drum & Bass',
	[`Attributes.${Attributes.electronic}`]: 'Electronic',
	[`Attributes.${Attributes.folk}`]: 'Folk',
	[`Attributes.${Attributes.funk}`]: 'Funk',
	[`Attributes.${Attributes.fusion}`]: 'Fusion',
	[`Attributes.${Attributes.hipHop}`]: 'Hip Hop',
	[`Attributes.${Attributes.jazz}`]: 'Jazz',
	[`Attributes.${Attributes.latin}`]: 'Latin',
	[`Attributes.${Attributes.metal}`]: 'Metal',
	[`Attributes.${Attributes.minimal}`]: 'Minimal',
	[`Attributes.${Attributes.pop}`]: 'Pop',
	[`Attributes.${Attributes.rnb}`]: 'R&b',
	[`Attributes.${Attributes.ragga}`]: 'Ragga',
	[`Attributes.${Attributes.reggae}`]: 'Reggae',
	[`Attributes.${Attributes.rock}`]: 'Rock',
	[`Attributes.${Attributes.soul}`]: 'Soul',
	[`Attributes.${Attributes.techno}`]: 'Techno',
	[`Attributes.${Attributes.trance}`]: 'Trance',
	[`Attributes.${Attributes.trap}`]: 'Trap',
	[`Attributes.${Attributes.african}`]: 'African',
	[`Attributes.${Attributes.indian}`]: 'Indian',
	[`Attributes.${Attributes.oriental}`]: 'Oriental',
	[`Attributes.${Attributes.southAmerican}`]: 'South-American',

	'AttributeInput.title':
		`{label}: {value, select,
			${YoloValue} {yolo - switch to yes}
			${MaxValue} {yes - switch to nope}
			${NopeValue} {nope - switch to yolo}
		}`,

	'FiltersForm.search.label': 'Search',
	'FiltersForm.search.placeholder': 'Track, artist...',
	'FiltersForm.moods': 'Moods',
	'FiltersForm.genres': 'Genres',
	'FiltersForm.influences': 'Influences',
	'FiltersForm.options': 'Options',
	'FiltersForm.randomized': 'Randomize results',
	'FiltersForm.highlights': 'Show only album highlights',
	'FiltersForm.unseen': 'Show only new tracks',
	'FiltersForm.advanced': 'Advanced mode',
	'FiltersForm.submit': 'Filter',

	'TrackTable.play': 'Play',
	'TrackTable.title': 'Title',
	'TrackTable.artist': 'Artist',
	'TrackTable.album': 'Album',
	'TrackTable.attributes': 'Attributes',
	'TrackTable.timeAdded': 'Added',

	'DeezerPlayer.loading': 'Loading Deezer player…',
	'SpotifyPlayer.loading': 'Loading Spotify player…',
	'YouTubePlayer.loading': 'Loading YouTube player…'
};
