import {takeLatest, select, put, call} from 'redux-saga/effects';
import {mapValues, keys, keyBy, get, identity, without} from 'lodash-es';
import {fetchUserProfile} from '../spotify/api';
import {selectTrackIds} from '../app/selectors';
import {setTracksAttributes} from '../data/actions';
import {downloadDataSaga} from '../data/sagas';
import {downloadMetaSaga} from '../metadata/sagas';
import {mapUserMetaToAttributes} from '../user/sagas';
import {fetchAlbumHighlights, fetchLatestDeezerTracks} from '../deezer/sagas';
import {fetchManyTracksMeta, mapSpotifyMetaToAttributes} from '../spotify/sagas';
import {selectSelectedTrackIds, selectLastSelectedTrackId, selectIsEditMode} from './selectors';
import {SET_TRACK_SELECTED, FETCH_LATEST_TRACKS, FETCH_SELECTED_TRACKS_META, AGGREGATE_TRACKS_META, setIsRefreshing, setSelectedTrackIds, setLastSelectedTrackId, TOGGLE_EDIT_MODE, SAVE_ALL, saveAll, FETCH_ALBUM_HIGHLIGHTS} from './actions';



function* toggleEditModeSage() {
	const edit = yield select(selectIsEditMode);

	if (edit) {
		// Dumb API call to ensure spotify is authorized
		// before beginning edition.
		yield call(fetchUserProfile);
	}
}

function* setTrackSelectedSaga({payload}) {
	const {id, selected, isMultiple, isRange} = payload;
	const selectedTrackIds = yield select(selectSelectedTrackIds);
	const lastSelectedTrackId = yield select(selectLastSelectedTrackId);

	if (isMultiple) {
		if (selected) {
			yield put(setLastSelectedTrackId(id));
		}

		yield put(setSelectedTrackIds(
			selected
				? selectedTrackIds.concat(id)
				: without(selectedTrackIds, id)
		));

		return;
	}

	if (selected && isRange && lastSelectedTrackId) {
		const trackIds = yield select(selectTrackIds);
		const lastIndex = trackIds.findIndex((i) => i === lastSelectedTrackId);
		const index = trackIds.findIndex((i) => i === id);

		yield put(setSelectedTrackIds(
			trackIds.slice(
				Math.min(lastIndex, index),
				Math.max(lastIndex, index) + 1
			)
		));

		return;
	}

	yield put(setLastSelectedTrackId(id));
	yield put(setSelectedTrackIds(selected ? [id] : []));
}

function* fetchAlbumHighlightsSaga() {
	yield put(setIsRefreshing(true));

	try {
		yield call(fetchAlbumHighlights);
	} catch (e) {
		console.error(e);
	}

	yield put(setIsRefreshing(false));
}

function* fetchLatestTracksSaga() {
	yield put(setIsRefreshing(true));

	try {
		yield call(fetchLatestDeezerTracks);
	} catch (e) {
		console.error(e);
	}

	yield put(setIsRefreshing(false));
}

function* fetchSelectedTracksMetaSaga() {
	const ids = yield select(selectSelectedTrackIds);
	yield call(fetchManyTracksMeta, ids);
}

export function* aggregateTracksMetaSaga() {
	const spotifyMetaAttributes = yield call(mapSpotifyMetaToAttributes);
	const spotifyIds = keys(spotifyMetaAttributes);
	const userMetaAttributes = yield call(mapUserMetaToAttributes);
	const userIds = keys(userMetaAttributes);
	const ids = keyBy([...spotifyIds, ...userIds], identity);

	const attributes = mapValues(ids, (id) => ({
		...get(spotifyMetaAttributes, id, {}),
		...get(userMetaAttributes, id, {})
	}));

	yield put(setTracksAttributes(attributes));
}

export function* saveAllSaga() {
	yield call(downloadDataSaga);
	yield call(downloadMetaSaga);
}

export function* watchToggleEditMode() {
	yield takeLatest(TOGGLE_EDIT_MODE, toggleEditModeSage);
}

export function* watchSetTrackSelected() {
	yield takeLatest(SET_TRACK_SELECTED, setTrackSelectedSaga);
}

export function* watchFetchAlbumHighlights() {
	yield takeLatest(
		FETCH_ALBUM_HIGHLIGHTS,
		fetchAlbumHighlightsSaga
	);
}

export function* watchFetchLatestTracks() {
	yield takeLatest(
		FETCH_LATEST_TRACKS,
		fetchLatestTracksSaga
	);
}

export function* watchFetchSelectedTracksMeta() {
	yield takeLatest(
		FETCH_SELECTED_TRACKS_META,
		fetchSelectedTracksMetaSaga
	);
}

export function* watchAggregateTracksMeta() {
	yield takeLatest(
		AGGREGATE_TRACKS_META,
		aggregateTracksMetaSaga
	);
}

export function* watchSaveAll() {
	yield takeLatest(SAVE_ALL, saveAllSaga);
}
