import {stubFalse, reject, has, map, differenceWith} from 'lodash-es';
import jsonp from 'jsonp';
import {fetchJson} from '../api/api';



export const PageSize = 25;

const formatTrack = ({id, title, duration, time_add}, artistId, albumId) => ({
	id: `${id}-dz`,
	deezerId: id,
	title,
	duration,
	timeAdded: time_add,
	artistId,
	albumId
});

const formatArtist = ({id, name}) => ({
	id: `${id}-dz`,
	deezerId: id,
	name
});

const formatAlbum = ({id, title}) => ({
	id: `${id}-dz`,
	deezerId: id,
	title
});

export const fetchTrackInfo = (id) =>
	new Promise((resolve, reject) => {
		const url = `https://api.deezer.com/track/${id}?output=jsonp`;
		jsonp(url, {}, (error, data) => {
			if (error) {
				reject(error);
			} else {
				resolve(data);
			}
		});
	});

const fetchApi = async (url, options) => {
	const response = await fetchJson(url, options);

	if (has(response, 'error')) {
		const {type, message} = response.error;
		throw new Error(`Deezer API error: ${message} (${type})`);
	}

	return response;
};

export const aggregateData = async (endpoint, stop = stubFalse) => {
	const tracks = {};
	const artists = {};
	const albums = {};
	let url = `/deezer${endpoint}`;

	while (url) {
		const {data, next} = await fetchApi(url);
		const filtered = reject(data, stop);

		if (!filtered.length) {
			break;
		}

		data.forEach((track) => {
			if (track.id in tracks) {
				return;
			}

			const artist = formatArtist(track.artist);
			artists[artist.id] = artist;

			const album = formatAlbum(track.album);
			albums[album.id] = album;

			const formatted = formatTrack(track, artist.id, album.id);
			tracks[formatted.id] = formatted;
		});

		if (!next) {
			break;
		}

		url = next.replace('https://api.deezer.com/', '/deezer/');
	};

	return {
		tracks,
		artists,
		albums
	};
};
