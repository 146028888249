export default {
	prefix: 'db',
	iconName: 'deezer',
	icon: [
		206,
		206,
		[],
		'f800',
		'M0 150h38v11H0zM0 135h38v11H0zM0 120h38v11H0zM0 105h38v11H0zM0 90h38v11H0zM42 150h38v11H42zM42 135h38v11H42zM42 120h38v11H42zM84 150h38v11H84zM84 135h38v11H84zM84 120h38v11H84zM84 105h38v11H84zM84 90h38v11H84zM84 75h38v11H84zM84 60h38v11H84zM168 150h38v11h-38zM168 135h38v11h-38zM168 120h38v11h-38zM168 105h38v11h-38zM168 90h38v11h-38zM168 75h38v11h-38zM168 60h38v11h-38zM168 45h38v11h-38zM126 150h38v11h-38zM126 135h38v11h-38zM126 120h38v11h-38zM126 105h38v11h-38zM126 90h38v11h-38z'
	]
};
