import update from 'immutability-helper';
import {mapValues} from 'lodash-es';
import {
	SET_META,
	UPDATE_TRACK_META,
	UPDATE_MANY_TRACKS_META,
	UPDATE_ARTIST_META,
	UPDATE_MANY_ARTISTS_META,
	UPDATE_ALBUM_META,
	UPDATE_MANY_ALBUMS_META
} from './actions';



const initialState = {
	tracks: {},
	artists: {},
	albums: {}
};

const updateMeta = (key, state, {namespace, id, meta}) =>
	update(state, {
		[key]: {
			[namespace]: {
				$auto: {
					[id]: {
						$auto: {
							$merge: meta
						}
					}
				}
			}
		}
	});

const updateManyMeta = (key, state, {namespace, meta}) =>
	update(state, {
		[key]: {
			[namespace]: {
				$auto: mapValues(meta, (m) => ({
					$auto: {
						$merge: m
					}
				}))
			}
		}
	});

export default (state = initialState, {type, payload}) => {
	switch (type) {
		case SET_META:
			return {
				...state,
				...payload
			};

		case UPDATE_TRACK_META:
			return updateMeta('tracks', state, payload);

		case UPDATE_MANY_TRACKS_META:
			return updateManyMeta('tracks', state, payload);

		case UPDATE_ARTIST_META:
			return updateMeta('artists', state, payload);

		case UPDATE_MANY_ARTISTS_META:
			return updateManyMeta('artists', state, payload);

		case UPDATE_ALBUM_META:
			return updateMeta('albums', state, payload);

		case UPDATE_MANY_ALBUMS_META:
			return updateManyMeta('albums', state, payload);

		default:
			return state;
	}
};
