import {property, mapValues, get} from 'lodash-es';
import {YoloValue} from '../data/constants';
import {selectTrack, selectTracks} from '../data/selectors';
import {uid} from './actions';



export const selectIsRefreshing = property([uid, 'isRefreshing']);
export const selectIsEditMode = property([uid, 'isEditMode']);
export const selectSelectedTrackIds = property([uid, 'selectedTrackIds']);
export const selectLastSelectedTrackId = property([uid, 'lastSelectedTrackId']);

export const selectIsEditing = (state) =>
	!!selectSelectedTrackIds(state).length;

export const selectSelectedTracks = (state) =>
	selectSelectedTrackIds(state).map((id) =>
		selectTrack(id)(state)
	);

export const selectSelectedTracksTitle = (state) =>
	selectSelectedTracks(state).map(property('title'));

export const selectSelectedTrackAttributes = (state) => {
	const selectedTrackIds = selectSelectedTrackIds(state);

	if (!selectedTrackIds.length) {
		return {};
	}

	const tracks = selectTracks(state);
	const copy = [...selectedTrackIds];
	const firstTrackId = copy.shift();
	const firstTrackAttributes = tracks[firstTrackId].attributes;

	return copy.reduce((attributes, trackId) =>
		mapValues(attributes, (value, id) => (
			value === get(tracks, [trackId, 'attributes', id])
				? value
				: YoloValue
		)),
		firstTrackAttributes
	);
};
