import React from 'react';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash-es';
import {SortKeys, SortDirections} from '../app/constants';
import {selectSortKey, selectSortDirection} from '../app/selectors';
import {sortTracks} from '../app/actions';



const SortOptions = [
	{
		id: 'none',
		label: 'None',
		key: SortKeys.none,
		direction: SortDirections.none
	},
	{
		id: 'titleAsc',
		label: 'Title (a-z)',
		key: SortKeys.title,
		direction: SortDirections.asc
	},
	{
		id: 'titleDesc',
		label: 'Title (z-a)',
		key: SortKeys.title,
		direction: SortDirections.desc
	},
	{
		id: 'artistAsc',
		label: 'Artist (a-z)',
		key: SortKeys.artist,
		direction: SortDirections.asc
	},
	{
		id: 'artistDesc',
		label: 'Artist (z-a)',
		key: SortKeys.artist,
		direction: SortDirections.desc
	},
	{
		id: 'albumAsc',
		label: 'Album (a-z)',
		key: SortKeys.album,
		direction: SortDirections.asc
	},
	{
		id: 'albumDesc',
		label: 'Album (z-a)',
		key: SortKeys.album,
		direction: SortDirections.desc
	},
	{
		id: 'timeAddedAsc',
		label: 'Added (oldest first)',
		key: SortKeys.timeAdded,
		direction: SortDirections.asc
	},
	{
		id: 'timeAddedDesc',
		label: 'Added (latest first)',
		key: SortKeys.timeAdded,
		direction: SortDirections.desc
	}
];

const TrackList = ({sortOption, onSort, children}) => (
	<div className="TrackList">
		<div className="TrackList-form Inline">
			<label htmlFor="TrackList-sort">Sort</label>

			<select
				id="TrackList-sort"
				value={sortOption}
				onChange={(event) =>
					onSort(event.target.value)
				}
			>
				{SortOptions.map(({id, label}) => (
					<option key={id} value={id}>{label}</option>
				))}
			</select>
		</div>

		<ul className="TrackList-items">
			{!isEmpty(children) && children.map((child) => (
				<li>{child}</li>
			))}
		</ul>
	</div>
);

export default connect(
	(state) => {
		const sortKey = selectSortKey(state);
		const sortDirection = selectSortDirection(state);
		const sortOption = SortOptions.find(({key, direction}) =>
			key === sortKey && direction === sortDirection
		);

		return {
			sortOption: sortOption.id
		};
	},
	(dispatch) => ({
		onSort(id) {
			const {key, direction} = SortOptions.find((option) =>
				option.id === id
			);

			dispatch(sortTracks(key, direction));
		}
	})
)(TrackList);
