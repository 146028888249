import React from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SortDirections} from '../app/constants';
import {selectSortKey, selectSortDirection} from '../app/selectors';
import {sortTracks} from '../app/actions';



const nextDirection = (direction) =>
	(direction === SortDirections.asc)
		? SortDirections.desc
		: SortDirections.asc;

const icon = (direction) => {
	switch (direction) {
		case SortDirections.asc:
			return 'sort-up';
		case SortDirections.desc:
			return 'sort-down';
		case SortDirections.none:
		default:
			return 'sort';
	}
};

const SortButton = ({direction, onSort}) => (
	<button
		className="Button Button--small"
		aria-pressed={direction !== SortDirections.none}
		onClick={() => {
			onSort(nextDirection(direction));
		}}
	>
		<FontAwesomeIcon icon={icon(direction)} />
	</button>
);

export default connect(
	(state, {sortKey}) => ({
		direction: (selectSortKey(state) === sortKey)
			? selectSortDirection(state)
			: SortDirections.none
	}),
	(dispatch, {sortKey}) => ({
		onSort(direction) {
			dispatch(sortTracks(sortKey, direction));
		}
	})
)(SortButton);
