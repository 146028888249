import {property} from 'lodash-es';
import {selectTrack, selectSomeTracks} from '../data/selectors';
import {getPage} from './api';
import {uid} from './actions';



export const selectIsPlaying = property([uid, 'isPlaying']);
export const selectPlayer = property([uid, 'player']);
export const selectCurrentPage = property([uid, 'page']);
export const selectPageSize = property([uid, 'pageSize']);
export const selectSearchIndex = property([uid, 'searchIndex']);
export const selectSortKey = property([uid, 'sortKey']);
export const selectSortDirection = property([uid, 'sortDirection']);
export const selectFilters = property([uid, 'filters']);
export const selectTrackIds = property([uid, 'trackIds']);
export const selectCurrentTrackId = property([uid, 'currentTrackId']);

export const selectPageCount = (state) =>
	Math.ceil(selectTrackIds(state).length / selectPageSize(state)) || 1;

export const selectCurrentTrack = (state) =>
	selectTrack(selectCurrentTrackId(state))(state);

export const selectPageTrackIds = (state) => {
	const page = selectCurrentPage(state);
	const size = selectPageSize(state);
	const ids = selectTrackIds(state);
	return getPage(ids, size, page);
};

export const selectPageTracks = (state) =>
	selectSomeTracks(selectPageTrackIds(state))(state);
