export const SortDirections = {
	none: 'none',
	asc: 'asc',
	desc: 'desc'
};

export const SortKeys = {
	none: 'none',
	title: 'title',
	artist: 'artist',
	album: 'album',
	timeAdded: 'timeAdded'
};

export const Players = {
	deezer: 'deezer',
	youTube: 'youTube',
	spotify: 'spotify'
};

export const Panels = {
	search: 'search',
	players: 'players',
	tracks: 'tracks',
	editor: 'editor'
};
