import {get, pick} from 'lodash-es';
import {selectTrack} from '../data/selectors';
import {uid} from './actions';



export const selectAllTracksMeta = (namespace) => (state) =>
	get(state, [uid, 'tracks', namespace], {});

export const selectTrackMeta = (namespace, id) => (state) =>
	get(state, [uid, 'tracks', namespace, id], {});

export const selectManyTracksMeta = (namespace, ids) => (state) =>
	pick(selectAllTracksMeta(namespace)(state), ids);

export const selectAllArtistsMeta = (namespace) => (state) =>
	get(state, [uid, 'artists', namespace], {});

export const selectArtistMeta = (namespace, id) => (state) =>
	get(state, [uid, 'artist', namespace, id], {});

export const selectTrackArtistMeta = (namespace, id) => (state) => {
	const artistId = get(selectTrack(id)(state), 'artistId');
	return selectArtistMeta(namespace, artistId)(state);
};

export const selectAllAlbumsMeta = (namespace) => (state) =>
	get(state, [uid, 'albums', namespace], {});

export const selectAlbumMeta = (namespace, id) => (state) =>
	get(state, [uid, 'album', namespace, id], {});

export const selectTrackAlbumMeta = (namespace, id) => (state) => {
	const albumId = get(selectTrack(id)(state), 'albumId');
	return selectAlbumMeta(namespace, albumId)(state);
};

export const selectDownloadableMeta = (state) =>
	pick(get(state, uid), [
		'tracks',
		'artists',
		'albums'
	]);
