import {select, put, call} from 'redux-saga/effects';
import {get, pickBy, invert, size, property, keys, mapKeys, mapValues, difference} from 'lodash-es';
import {updateTrack} from '../data/actions';
import {selectTrack, selectTrackArtist, selectTrackAlbum, selectTracks} from '../data/selectors';
import {uid, fetchMatchingTrack, fetchManyTrackFeatures, fetchArtistGenres, fetchAlbumGenres, mapTrackAttributes} from './api';
import {updateTrackMeta, updateArtistMeta, updateAlbumMeta, updateManyTracksMeta} from '../metadata/actions';
import {setMessage} from '../status/actions';
import {selectManyTracksMeta, selectArtistMeta, selectAlbumMeta, selectAllTracksMeta, selectAllAlbumsMeta, selectAllArtistsMeta} from '../metadata/selectors';



function* fetchMatchingTrackMeta(id) {
	const track = yield select(selectTrack(id));
	const artist = yield select(selectTrackArtist(id));
	const artistMeta = yield select(selectArtistMeta(uid, artist.id));
	const album = yield select(selectTrackAlbum(id));
	const albumMeta = yield select(selectAlbumMeta(uid, album.id));

	yield put(setMessage(`Fetching spotify metadata for "${track.title}"`));

	const spotifyTrack = yield call(fetchMatchingTrack, track, artist, album);

	if (!spotifyTrack) {
		return;
	}

	const trackSpotifyId = get(spotifyTrack, 'id');
	const trackUpdates = pickBy({
		spotifyId: trackSpotifyId
	});

	if (size(trackUpdates)) {
		yield put(updateTrack(id, trackUpdates));
	}

	const trackMetaUpdates = pickBy({
		isrc: get(spotifyTrack, 'external_ids.isrc'),
		id: trackSpotifyId
	});

	if (size(trackMetaUpdates)) {
		yield put(updateTrackMeta(uid, id, trackMetaUpdates));
	}

	const artistSpotifyId = get(spotifyTrack, 'artists[0].id');
	const artistMetaUpdates = pickBy({
		isrc: get(spotifyTrack, 'artists[0].external_ids.isrc'),
		id: artistSpotifyId,
		genres: (artistSpotifyId && !('genres' in artistMeta))
			? yield call(fetchArtistGenres, artistSpotifyId)
			: []
	});

	if (size(artistMetaUpdates)) {
		yield put(updateArtistMeta(uid, artist.id, artistMetaUpdates));
	}

	const albumSpotifyId = get(spotifyTrack, 'album.id');
	const albumMetaUpdates = pickBy({
		isrc: get(spotifyTrack, 'album.external_ids.isrc'),
		id: albumSpotifyId,
		genres: (albumSpotifyId && !('genres' in albumMeta))
			? yield call(fetchAlbumGenres, albumSpotifyId)
			: []
	});

	if (size(albumMetaUpdates)) {
		yield put(updateAlbumMeta(uid, album.id, albumMetaUpdates));
	}

	yield put(setMessage(`Successfully fetched spotify metadata for "${track.title}"`));
};

function* fetchManyMatchingTrackMeta(ids) {
	const meta = yield select(selectManyTracksMeta(uid, ids));
	const diff = difference(ids, keys(meta));

	for (const id of diff) {
		yield call(fetchMatchingTrackMeta, id);
	}
}

export function* fetchManyTracksMeta(ids) {
	yield call(fetchManyMatchingTrackMeta, ids);

	const meta = yield select(selectManyTracksMeta(uid, ids));
	const spotifyIdsByTrackIds = mapValues(meta, property('id'));
	const trackIdsBySpotifyIds = invert(spotifyIdsByTrackIds);
	const spotifyIds = keys(trackIdsBySpotifyIds);

	if (!size(trackIdsBySpotifyIds)) {
		return;
	}

	const features = yield call(fetchManyTrackFeatures, spotifyIds);
	const keyed = mapKeys(features, (_, id) =>
		trackIdsBySpotifyIds[id]
	);

	const values = mapValues(keyed, (features) => ({
		features
	}));

	yield put(updateManyTracksMeta(uid, values));
};

export function* mapSpotifyMetaToAttributes() {
	const tracks = yield select(selectTracks);
	const tracksMeta = yield select(selectAllTracksMeta(uid));
	const artistMeta = yield select(selectAllArtistsMeta(uid));
	const albumsMeta = yield select(selectAllAlbumsMeta(uid));
	const attributes = mapValues(tracks, (track, id) =>
		mapTrackAttributes(
			tracksMeta[id],
			artistMeta[track.artistId],
			albumsMeta[track.albumId]
		)
	);

	return pickBy(attributes, size);
};
