import {createStore} from 'redux-dynamic-modules';
import {getSagaExtension} from 'redux-dynamic-modules-saga';
import data from './data/module';
import metadata from './metadata/module';
import admin from './admin/module';
import status from './status/module';
import user from './user/module';
import app from './app/module';



export default createStore(
	{},
	[],
	[
		getSagaExtension()
	],
	data(),
	metadata(),
	admin(),
	status(),
	user(),
	app()
);
