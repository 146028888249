import React from 'react';
import Modal from 'react-modal';



export default (props) => (
	<Modal
		className="Modal"
		overlayClassName="Modal--backdrop"
		{...props}
	/>
);
