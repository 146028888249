import {takeLatest, put, select} from 'redux-saga/effects';
import {property, omitBy, keyBy, mapValues, constant, identity, pickBy, size} from 'lodash-es';
import {isYoloValue} from '../data/constants';
import {allAttributes} from '../data/attributes';
import {updateManyTracksMeta} from '../metadata/actions';
import {selectAllTracksMeta} from '../metadata/selectors';
import {selectSelectedTrackIds} from '../admin/selectors';
import {SET_SELECTED_TRACKS_ATTRIBUTES, uid} from './actions';



function* setSelectedTracksAttributesSaga({payload}) {
	const ids = yield select(selectSelectedTrackIds);
	const attributes = omitBy(payload, isYoloValue);
	const meta = mapValues(keyBy(ids, identity), constant({attributes}));
	yield put(updateManyTracksMeta(uid, meta));
};

export function* mapUserMetaToAttributes() {
	const meta = yield select(selectAllTracksMeta(uid));
	const attributes = mapValues(meta, property('attributes'));
	const allowedAttributes = mapValues(attributes, (attrs) =>
		pickBy(attrs, (_, id) => allAttributes.includes(id))
	);

	return pickBy(allowedAttributes, size);
};

export function* watchSetSelectedTracksAttributes() {
	yield takeLatest(
		SET_SELECTED_TRACKS_ATTRIBUTES,
		setSelectedTracksAttributesSaga
	);
}
