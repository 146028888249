import React from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {setPlayer} from '../app/actions';



const PlayerSelector = ({onSelect}) => (
	<button className="Button" onClick={onSelect}>
		<FontAwesomeIcon icon={['fa', 'cog']} />
	</button>
);

export default connect(
	null,
	(dispatch) => ({
		onSelect() {
			dispatch(setPlayer(null));
		}
	})
)(PlayerSelector);
