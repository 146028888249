import {uid} from './actions';
import reducer from './reducer';
import {watchSetMessage} from './sagas';



export default () => ({
	id: uid,
	reducerMap: {
		[uid]: reducer
	},
	sagas: [
		watchSetMessage
	]
});
