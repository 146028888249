import React from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {selectIsRefreshing, selectIsEditMode} from './selectors';
import {toggleEditMode, fetchSelectedTracksMeta, aggregateTracksMeta, fetchLatestTracks, saveAll, fetchAlbumHighlights} from './actions';



const AdminTools = ({isEditMode, isRefreshing, onToggleEditMode, onFetchAlbumHighlights, onFetchLatestTracks, onFetchMeta, onAggregateMeta, onSaveAll}) => (
	<div className="AdminTools">
		<button
			className="Button"
			type="button"
			aria-pressed={isEditMode}
			onClick={onToggleEditMode}
		>
			<FontAwesomeIcon icon="pen" />
		</button>

		<button
			className="Button"
			type="button"
			disabled={isRefreshing}
			onClick={onFetchAlbumHighlights}
		>
			<FontAwesomeIcon icon="star" />
		</button>

		<button
			className="Button"
			type="button"
			disabled={isRefreshing}
			onClick={onFetchLatestTracks}
		>
			<FontAwesomeIcon icon="sync-alt" />
		</button>

		<button
			className="Button"
			type="button"
			onClick={onFetchMeta}
		>
			<FontAwesomeIcon icon="database" />
		</button>

		<button
			className="Button"
			type="button"
			onClick={onAggregateMeta}
		>
			<FontAwesomeIcon icon="tags" />
		</button>

		<button
			className="Button"
			type="button"
			onClick={onSaveAll}
		>
			<FontAwesomeIcon icon="save" />
		</button>
	</div>
);

export default connect(
	(state) => ({
		isEditMode: selectIsEditMode(state),
		isRefreshing: selectIsRefreshing(state)
	}),
	(dispatch) => ({
		onToggleEditMode(playing) {
			dispatch(toggleEditMode(playing));
		},
		onFetchAlbumHighlights() {
			dispatch(fetchAlbumHighlights());
		},
		onFetchLatestTracks() {
			dispatch(fetchLatestTracks());
		},
		onFetchMeta() {
			dispatch(fetchSelectedTracksMeta());
		},
		onAggregateMeta() {
			dispatch(aggregateTracksMeta());
		},
		onSaveAll() {
			dispatch(saveAll());
		}
	})
)(AdminTools);
