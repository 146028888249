import update from 'immutability-helper';
import ls from 'local-storage';
import {SortKeys, SortDirections} from './constants';
import {getPage} from './api';
import {
	SET_IS_PLAYING,
	SET_CURRENT_TRACK_ID,
	SET_PLAYER,
	SET_FILTERS,
	SET_SORTED_TRACKS,
	SET_FILTERED_TRACKS,
	SET_PAGE,
	PLAY_PREVIOUS,
	PLAY_NEXT,
	PLAY
} from './actions';



const initialState = {
	isPlaying: false,
	player: ls.get('player'),
	sortKey: SortKeys.timeAdded,
	sortDirection: SortDirections.asc,
	filters: {
		query: '',
		attributes: {},
		randomized: false,
		highlights: false,
		unseen: false
	},
	trackIds: [],
	currentTrackId: null,
	page: 0,
	pageSize: 50
};

export default (state = initialState, {type, payload}) => {
	switch (type) {
		case SET_IS_PLAYING:
			return {
				...state,
				isPlaying: payload
			};

		case SET_CURRENT_TRACK_ID:
			return {
				...state,
				currentTrackId: payload
			};

		case SET_PLAYER:
			// shitty side-effect
			ls.set('player', payload);

			return update(state, {
				player: {
					$set: payload
				}
			});

		case SET_FILTERS:
			return update(state, {
				filters: {
					$set: payload
				}
			});

		case SET_SORTED_TRACKS:
			return {
				...state,
				sortKey: payload.key,
				sortDirection: payload.direction,
				trackIds: payload.ids
			};

		case SET_FILTERED_TRACKS:
			return {
				...state,
				sortKey: SortKeys.none,
				sortDirection: SortDirections.none,
				trackIds: payload
			};

		case SET_PAGE:
			return {
				...state,
				page: payload
			};

		case PLAY:
			return {
				...state,
				isPlaying: (state.currentTrackId === payload)
					? !state.isPlaying
					: true,
				currentTrackId: payload
			};

		case PLAY_PREVIOUS: {
			const {trackIds, currentTrackId, pageSize, page} = state;
			const ids = getPage(trackIds, pageSize, page);
			const index = ids.findIndex((id) =>
				(id === currentTrackId)
			);

			if (index < 1 || index > ids.length - 1) {
				return state;
			}

			return {
				...state,
				currentTrackId: ids[index - 1]
			};
		}

		case PLAY_NEXT: {
			const {trackIds, currentTrackId, pageSize, page} = state;
			const ids = getPage(trackIds, pageSize, page);
			const index = ids.findIndex((id) =>
				(id === currentTrackId)
			);

			if (index < 0 || index > ids.length - 2) {
				return state;
			}

			return {
				...state,
				currentTrackId: ids[index + 1]
			};
		}

		default:
			return state;
	}
};
