import React, {Component} from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash-es';
import {selectIsPlaying} from '../app/selectors';
import {setIsPlaying, playNext} from '../app/actions';
import {selectCurrentTrackDeezerId} from './selectors';
import {fetchTrackInfo} from './api';
import Player from '../player/Player';



const DefaultThumbnail = '';

export class DeezerPlayerController extends Component {

	constructor(props) {
		super(props);
		this.handlePosition = this.handlePosition.bind(this);
		this.state = {
			title: '',
			thumbnailUrl: DefaultThumbnail,
			currentTime: 0,
			totalTime: 0
		};
	}

	//
	componentDidMount() {
		const {events} = this.props;
		events.subscribe('player_position', this.handlePosition);

		this.loadCurrentTrack();
	}

	componentDidUpdate({
		isPlaying: wasPlaying,
		currentTrackId: previousTrackId
	}) {
		const {isPlaying, currentTrackId, player} = this.props;

		if (currentTrackId !== previousTrackId) {
			this.loadCurrentTrack();

			// avoids playing the current track for a second
			// before changing
			return;
		}

		if (isPlaying !== wasPlaying) {
			isPlaying
				? player.play()
				: player.pause();
		}
	}

	componentWillUnmount() {
		const {player, events} = this.props;

		events.unsubscribe('player_position', this.handlePosition);

		if (player.isPlaying()) {
			player.pause();
		}
	}

	loadCurrentTrack() {
		if (this.props.currentTrackId) {
			this.loadTrack(this.props.currentTrackId);
		}
	}

	loadTrack(id) {
		this.props.player.playTracks([id], this.props.isPlaying);
		this.setState(() => ({
			title: '',
			thumbnailUrl: DefaultThumbnail,
			currentTime: 0,
			totalTime: 0
		}));

		fetchTrackInfo(id).then(({title, album}) => {
			this.setState(() => ({
				title,
				thumbnailUrl: get(album, 'cover_medium', '')
			}));
		});
	}

	handlePosition([currentTime, totalTime]) {
		if (totalTime && currentTime >= totalTime) {
			this.props.onEnd();
		} else {
			this.setState(() => ({
				currentTime,
				totalTime
			}));
		}
	}

	handleSeek(seconds) {
		if (this.state.totalTime) {
			this.props.player.seek((seconds / this.state.totalTime) * 100);
		}
	}

	render() {
		const {currentTrackId} = this.props;
		const {title, thumbnailUrl, totalTime, currentTime} = this.state;

		return (
			<Player
				isDisabled={!currentTrackId}
				title={title}
				thumbnailUrl={thumbnailUrl}
				totalTime={totalTime}
				currentTime={currentTime}
				onSeek={this.handleSeek.bind(this)}
				url={
					currentTrackId
						? `https://www.deezer.com/track/${currentTrackId}`
						: ''
				}
			/>
		);
	}
}

export default connect(
	(state) => ({
		isPlaying: selectIsPlaying(state),
		currentTrackId: selectCurrentTrackDeezerId(state)
	}),
	(dispatch) => ({
		onEnd() {
			dispatch(playNext());
		},
		onPlay(playing) {
			dispatch(setIsPlaying(playing));
		}
	})
)(DeezerPlayerController);
