export const uid = 'app';

export const SET_IS_PLAYING = `${uid}/SET_IS_PLAYING`;
export const SET_CURRENT_TRACK_ID = `${uid}/SET_CURRENT_TRACK_ID`;
export const SET_PLAYER = `${uid}/SET_PLAYER`;
export const SET_FILTERS = `${uid}/SET_FILTERS`;
export const SET_SORTED_TRACKS = `${uid}/SET_SORTED_TRACKS`;
export const SET_FILTERED_TRACKS = `${uid}/SET_FILTERED_TRACKS`;
export const FILTER = `${uid}/FILTER`;
export const SORT_TRACKS = `${uid}/SORT_TRACKS`;
export const SET_PAGE = `${uid}/SET_PAGE`;
export const PLAY = `${uid}/PLAY`;
export const PLAY_PREVIOUS = `${uid}/PLAY_PREVIOUS`;
export const PLAY_NEXT = `${uid}/PLAY_NEXT`;

export const setIsPlaying = (playing) => ({
	type: SET_IS_PLAYING,
	payload: playing
});

export const setCurrentTrackId = (id) => ({
	type: SET_CURRENT_TRACK_ID,
	payload: id
});

export const setPlayer = (player) => ({
	type: SET_PLAYER,
	payload: player
});

export const setFilters = (filters) => ({
	type: SET_FILTERS,
	payload: filters
});

export const setSortedTracks = (key, direction, ids) => ({
	type: SET_SORTED_TRACKS,
	payload: {key, direction, ids
	}
});

export const filter = (filters) => ({
	type: FILTER,
	payload: filters
});

export const setFilteredTracks = (ids) => ({
	type: SET_FILTERED_TRACKS,
	payload: ids
});

export const sortTracks = (key, direction) => ({
	type: SORT_TRACKS,
	payload: {key, direction}
});

export const setPage = (page) => ({
	type: SET_PAGE,
	payload: page
});

export const play = (id) => ({
	type: PLAY,
	payload: id
});

export const playPrevious = () => ({
	type: PLAY_PREVIOUS,
	payload: {}
});

export const playNext = () => ({
	type: PLAY_NEXT,
	payload: {}
});
