import {pick, pickBy, get, property} from 'lodash-es';
import {uid} from './actions';



export const selectTracks = property([uid, 'tracks']);
export const selectArtists = property([uid, 'artists']);
export const selectAlbums = property([uid, 'albums']);
export const selectTimestamp = property([uid, 'timestamp']);
export const selectLastTimestamp = property([uid, 'lastTimestamp']);

export const selectUnseenTracks = (state) => {
	const lastTimestamp = selectLastTimestamp(state);
	return pickBy(selectTracks(state), (track) =>
		track.timeAdded > lastTimestamp
	);
};

export const selectTrack = (id) => (state) =>
	get(state, [uid, 'tracks', id]);

export const selectSomeTracks = (ids) => (state) =>
	pick(selectTracks(state), ids);

export const selectTrackArtist = (id) => (state) =>
	get(selectArtists(state), [get(selectTrack(id)(state), 'artistId')]);

export const selectTrackAlbum = (id) => (state) =>
	get(selectAlbums(state), [get(selectTrack(id)(state), 'albumId')]);

export const selectDownloadableData = (state) =>
	pick(get(state, uid), [
		'tracks',
		'artists',
		'albums'
	]);
