import React from 'react';
import classNames from 'classnames';
import {mapProps} from 'recompose';
import {isUndefined} from 'lodash-es';
import {YoloValue, isNopeValue} from '../data/constants';



const RangeInput = ({id, name, min, max, value, options, disabled, onChange}) => {
	const labelId = `${id}-description`;

	return (
		<div
			className={classNames({
				RangeInput: true,
				'RangeInput--nope': isNopeValue(value)
			})}
		>
			<input
				type="range"
				id={id}
				name={name}
				min={min}
				max={max}
				value={value}
				disabled={disabled}
				onChange={onChange}
				aria-describedby={labelId}
			/>
			<span id={labelId}>
				{options[value]}
			</span>
		</div>
	);
};

export default mapProps(({value, ...props}) => ({
	...props,
	value: isUndefined(value) ? YoloValue : value
}))(RangeInput);
