import React from 'react';
import {connect} from 'react-redux';
import {Players as PlayerTypes} from '../app/constants';
import {selectPlayer} from '../app/selectors';
import DeezerPlayer from '../deezer/DeezerPlayer';
import YouTubePlayer from '../youTube/YouTubePlayer';
import SpotifyPlayer from '../spotify/SpotifyPlayer';



const Players = ({player}) => {
	switch (player) {
		case PlayerTypes.deezer:
			return <DeezerPlayer key={PlayerTypes.deezer} />;
		case PlayerTypes.youTube:
			return <YouTubePlayer key={PlayerTypes.youTube} />;
		case PlayerTypes.spotify:
			return <SpotifyPlayer key={PlayerTypes.spotify} />;
		default:
			return null;
	}
};

export default connect((state) => ({
	player: selectPlayer(state)
}))(Players);
