import {padStart} from 'lodash-es';



export const formatDuration = (duration) => {
	const minutes = padStart(Math.floor(duration / 60), 2, '0');
	const seconds = padStart(duration % 60, 2, '0');
	return `${minutes}:${seconds}`;
};

export const formatDate = (timestamp) =>
	(new Date(1000 * parseInt(timestamp, 10))).toLocaleDateString();

export const compare = (a, b) => {
	if (typeof a === 'string') {
		return a.localeCompare(b);
	}

	if (a < b) {
		return -1;
	}

	if (a > b) {
		return 1;
	}

	return 0;
};

export const baseLocation = () => {
	const {protocol, host} = document.location;
	return `${protocol}//${host}`;
};

export const currentLocation = () =>
	`${baseLocation()}${document.location.pathname}`;

export const fetchJson = async (url, options) => {
	const response = await fetch(url, options);

	if (!response.ok) {
		throw new Error();
	}

	return await response.json();
};

export const fetchData = (name) =>
	fetchJson(`/${name}.json`);

export const saveFile = (name, data) => {
	const json = JSON.stringify(data, null, '	');
	const blob = new Blob([json], {
		type: 'application/json'
	});

	return fetch(`/save?name=${name}.json`, {
		method: 'post',
		body: blob
	});
};
