import {select, call} from 'redux-saga/effects';
import {saveFile} from '../api/api';
import {selectDownloadableMeta} from './selectors';
import {uid} from './actions';



export function* downloadMetaSaga() {
	yield call(saveFile, uid, yield select(selectDownloadableMeta));
}
