import React from 'react';
import {injectIntl} from 'react-intl';
import Player from '../player/Player';
import YouTubeApiProvider from './YouTubeApiProvider';
import YouTubePlayerController from './YouTubePlayerController';



const YouTubePlayer = ({intl}) => (
	<YouTubeApiProvider
		renderLoading={() => (
			<Player
				isDisabled
				title={intl.formatMessage({
					id: 'YouTubePlayer.loading'
				})}
			/>
		)}
		render={(props) => (
			<YouTubePlayerController {...props} />
		)}
	/>
);

export default injectIntl(YouTubePlayer);
