import React from 'react';
import {injectIntl} from 'react-intl';
import {Field} from 'formik';
import AttributeInput from './AttributeInput';



const AttributeInputList = ({namespace, ids, intl}) => (
	<div>
		{ids.map((id) => (
			<Field
				name={[namespace, id].join('.')}
				render={({field, form}) => (
					<AttributeInput
						label={intl.formatMessage({
							id: `Attributes.${id}`
						})}
						value={field.value}
						onChange={form.setFieldValue.bind(form, field.name)}
					/>
				)}
			/>
		))}
	</div>
);

export default injectIntl(AttributeInputList);
