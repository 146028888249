import React from 'react';
import {connect} from 'react-redux';
import formatDuration from 'format-duration';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {selectIsPlaying} from '../app/selectors';
import {setIsPlaying, playPrevious, playNext} from '../app/actions';
import PlayButton from '../components/PlayButton';
import PlayerSelector from '../components/PlayerSelector';



const formatSeconds = (seconds) =>
	formatDuration(seconds * 1000, {
		leading: true
	});

const Link = ({url, ...props}) =>
	url
		? <a href={url} {...props} />
		: <p {...props} />

const Player = ({isPlaying, isDisabled, title, url, thumbnailUrl, currentTime, totalTime, onChangePlayingState, onPrevious, onNext, onSeek}) => (
	<div className="Player">
		<Link className="Player-aside" url={url} title={title}>
			<img className="Player-thumbnail" src={thumbnailUrl} alt="" />
		</Link>

		<div className="Player-main">
			<div className="Player-info">
				<p className="Player-title">
					<Link url={url} title={title}>{title}</Link>
				</p>

				<p className="Player-durations">
					{formatSeconds(currentTime)}
					{' / '}
					{formatSeconds(totalTime)}
				</p>
			</div>

			<div className="Player-time">
				<div className="Player-totalTime" />

				<div
					className="Player-elapsedTime"
					style={{
						width: `${(currentTime / totalTime) * 100}%`
					}}
				/>

				<input
					className="Player-seekInput"
					type="range"
					title="Volume"
					min={0}
					max={totalTime}
					value={currentTime}
					disabled={isDisabled}
					onChange={(event) => {
						onSeek(event.currentTarget.value);
					}}
				/>
			</div>

			<div className="Player-actions">
				<div className="Player-controls">
					<button className="Button" onClick={onPrevious} disabled={isDisabled}>
						<FontAwesomeIcon icon="step-backward" />
					</button>

					<PlayButton
						isPlaying={isPlaying}
						disabled={isDisabled}
						onClick={() =>
							onChangePlayingState(!isPlaying)
						}
					/>

					<button className="Button" onClick={onNext} disabled={isDisabled}>
						<FontAwesomeIcon icon="step-forward" />
					</button>
				</div>

				<PlayerSelector />
			</div>
		</div>
	</div>
);

Player.defaultProps = {
	isDisabled: false,
	title: '',
	currentTime: 0,
	totalTime: 0
};

export default connect(
	(state) => ({
		isPlaying: selectIsPlaying(state)
	}),
	(dispatch) => ({
		onChangePlayingState(isPlaying) {
			dispatch(setIsPlaying(isPlaying));
		},
		onPrevious() {
			dispatch(playPrevious());
		},
		onNext() {
			dispatch(playNext());
		}
	})
)(Player);
