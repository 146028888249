import React from 'react';
import {formatDate} from '../api/api';
import AttributeList from './AttributeList';
import PlayButton from './PlayButton';



const TrackRow = ({track, artist, album, isCurrent, isPlaying, isSelected, onPlay, onSelect}) => {
	const {title, timeAdded, attributes} = track;
	const className = (isCurrent || isSelected)
		? 'important'
		: ''

	return (
		<tr className={className} onClick={onSelect}>
			<td className="TrackTable-state">
				<PlayButton
					isPlaying={isCurrent && isPlaying}
					onClick={onPlay}
				/>
			</td>
			<td>
				{title}
			</td>
			<td className="TrackTable-artist">
				{artist && artist.name}
			</td>
			<td className="TrackTable-album">
				{album && album.title}
			</td>
			<td>
				<AttributeList attributes={attributes} />
			</td>
			<td className="TrackTable-date">
				{formatDate(timeAdded)}
			</td>
		</tr>
	);
};

export default TrackRow;
