export const YoloValue = -1;
export const NopeValue = -2;
export const MaxValue = 5;

export const isYoloValue = (value) =>
	(value === YoloValue);

export const isNopeValue = (value) =>
	(value === NopeValue);

export const isMaxValue = (value) =>
	(value === MaxValue);

export const isYepValue = (value) =>
	(value > 0);
