import {mapValues} from 'lodash-es';
import update from 'immutability-helper';
import {
	SET_DATA,
	UPDATE_TRACK,
	UPDATE_MANY_TRACKS,
	SET_TRACKS_ATTRIBUTES,
	UPDATE_ARTIST,
	UPDATE_MANY_ARTISTS,
	UPDATE_ALBUM,
	UPDATE_MANY_ALBUMS
} from './actions';



const initialState = {
	timestamp: 0,
	lastTimestamp: 0,
	tracks: {},
	artists: {},
	albums: {},
};

const updateValues = (key, state, {id, values}) =>
	update(state, {
		[key]: {
			[id]: {
				$auto: {
					$merge: values
				}
			}
		}
	});

const updateManyValues = (key, state, values) =>
	update(state, {
		[key]: mapValues(values, (v) => ({
			$auto: {
				$merge: v
			}
		}))
	});

export default (state = initialState, {type, payload}) => {
	switch (type) {
		case SET_DATA:
			return {
				...state,
				...payload
			};

		case SET_TRACKS_ATTRIBUTES:
			return update(state, {
				tracks: mapValues(payload, (attributes = {}) => ({
					attributes: {
						$set: attributes
					}
				}))
			});

		case UPDATE_TRACK:
			return updateValues('tracks', state, payload);

		case UPDATE_MANY_TRACKS:
			return updateManyValues('tracks', state, payload);

		case UPDATE_ARTIST:
			return updateValues('artists', state, payload);

		case UPDATE_MANY_ARTISTS:
			return updateManyValues('artists', state, payload);

		case UPDATE_ALBUM:
			return updateValues('albums', state, payload);

		case UPDATE_MANY_ALBUMS:
			return updateManyValues('albums', state, payload);

		default:
			return state;
	}
};
