import React from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import {map, pickBy, get} from 'lodash-es';
import {YoloValue, isYoloValue, isYepValue, isNopeValue} from '../data/constants';
import {selectFilters} from '../app/selectors';



const Attribute = ({id, value, isWanted, isUnwanted}) => (
	<span>
		<span
			className={classNames({
				Attribute: true,
				'Attribute--wanted': isWanted,
				'Attribute--unwanted': isUnwanted
			})}
		>
			<FormattedMessage id={`Attributes.${id}`} />
			{' '}
			<span className="Attribute-value">{'♪'.repeat(value)}</span>
		</span>
		{' '}
	</span>
);

const AttributeList = ({attributes, filters}) => (
	<div>
		{map(attributes, (attribute, key) => (
			<Attribute
				key={key}
				id={key}
				value={attribute}
				isWanted={isYepValue(filters[key])}
				isUnwanted={isNopeValue(filters[key])}
			/>
		))}
	</div>
);

export default connect((state, {attributes}) => {
	const {attributes: filters} = selectFilters(state);

	return {
		filters,
		attributes: pickBy(attributes, (_, key) =>
			!isYoloValue(get(filters, key, YoloValue))
		)
	};
})(AttributeList);
