import React from 'react';
import {connect} from 'react-redux';
import {selectMessage} from './selectors';



const StatusBar = ({message}) => (
	message
		? (
			<div className="StatusBar">
				{message}
			</div>
		)
		: null
);

export default connect((state) => ({
	message: selectMessage(state)
}))(StatusBar);
