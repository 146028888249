export const uid = 'status';

export const SET_MESSAGE = `${uid}/SET_MESSAGE`;
export const CLEAR_MESSAGE = `${uid}/CLEAR_MESSAGE`;

export const setMessage = (message) => ({
	type: SET_MESSAGE,
	payload: message
});

export const clearMessage = () => ({
	type: SET_MESSAGE,
	payload: {}
});
