import {delay} from 'redux-saga';
import {takeLatest, put} from 'redux-saga/effects';
import {SET_MESSAGE, clearMessage} from './actions';



const Timeout = 5000;

function* clearLatestMessage() {
	yield delay(Timeout);
	yield put(clearMessage());
}

export function* watchSetMessage() {
	yield takeLatest(SET_MESSAGE, clearLatestMessage);
}
