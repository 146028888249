import React, {Component} from 'react';
import {baseLocation} from '../api/api';
import {loadScript} from '../api/dom';



const ElementId = 'YouTubePlayer';

export default class YouTubeApiProvider extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false
		};
	}

	componentDidMount() {
		if (window.dbYouTubePlayer) {
			this.setLoaded();
			return;
		}

		const element = document.createElement('div');
		element.setAttribute('id', ElementId);
		element.classList.add(ElementId);
		element.style = 'position: absolute; left: -9999px';
		document.body.appendChild(element);

		loadScript('https://www.youtube.com/iframe_api', () => {
			window.onYouTubeIframeAPIReady = () => {
				window.dbYouTubePlayer = new window.YT.Player(ElementId, {
					events: {
						onReady: this.setLoaded.bind(this)
					}
				});
			};
		});
	}

	setLoaded() {
		this.setState(() => ({
			isLoaded: true
		}));
	}

	render() {
		return this.state.isLoaded
			? this.props.render({
				player: window.dbYouTubePlayer
			})
			: this.props.renderLoading();
	}
}
