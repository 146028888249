import {uid} from './actions';
import reducer from './reducer';
import {watchToggleEditMode, watchSetTrackSelected, watchFetchAlbumHighlights, watchFetchLatestTracks, watchFetchSelectedTracksMeta, watchAggregateTracksMeta, watchSaveAll} from './sagas';



export default () => ({
	id: uid,
	reducerMap: {
		[uid]: reducer
	},
	sagas: [
		watchToggleEditMode,
		watchSetTrackSelected,
		watchFetchAlbumHighlights,
		watchFetchLatestTracks,
		watchFetchSelectedTracksMeta,
		watchAggregateTracksMeta,
		watchSaveAll
	]
});
