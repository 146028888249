import React from 'react';
import {injectIntl} from 'react-intl';
import {get} from 'lodash-es';
import {YoloValue} from '../data/constants';
import {DataList, DataListRow} from './DataList';
import RangeInput from './RangeInput';



const RangeInputList = ({prefix, namespace, ids, values, handleChange, intl, ...props}) => (
	<DataList>
		{ids.map((id) => (
			<DataListRow
				label={intl.formatMessage({
					id: `Attributes.${id}`
				})}
			>
				<RangeInput
					{...props}
					id={[prefix, id].join('.')}
					name={[namespace, id].join('.')}
					value={get(values, id, YoloValue)}
					onChange={handleChange}
				/>
			</DataListRow>
		))}
	</DataList>
);

export default injectIntl(RangeInputList);
