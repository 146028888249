import {flatMap, uniq, get} from 'lodash-es';
import {selectAllArtistsMeta, selectAllAlbumsMeta} from '../metadata/selectors';
import {selectCurrentTrack} from '../app/selectors';
import {uid} from './api';



export const selectAllGenres = (state) => {
	const artists = selectAllArtistsMeta(uid)(state)
	const artistGenres = flatMap(artists, (artist) =>
		get(artist, 'genres', [])
	);

	const albums = selectAllAlbumsMeta(uid)(state)
	const albumGenres = flatMap(albums, (album) =>
		get(album, 'genres', [])
	);

	return uniq([
		...artistGenres,
		...albumGenres
	]);
}

export const selectCurrentTrackSpotifyId = (state) =>
	get(selectCurrentTrack(state), 'spotifyId');
