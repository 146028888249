import {delay} from 'redux-saga';
import {stringify} from 'query-string';
import ls from 'local-storage';
import {get, pickBy, mapValues, keyBy, property, omit, reduce, add, set} from 'lodash-es';
import {MaxValue} from '../data/constants';
import {baseLocation} from '../api/api';
import {genreMappings, featuresMappings} from '../data/attributes';



export const uid = 'spotify';

const tokenKey = 'spotify.token';
const apiUrl = (process.env.NODE_ENV === 'production')
	? 'https://api.spotify.com/v1'
	: '/spotify';

export const Messages = {
	error: 'spotify.error',
	token: 'spotify.token'
};

const requestToken = () => {
	if (!window.dbSpotifyAuthPromise) {
		const popup = window.open(
			`${baseLocation()}/spotify-auth.html`,
			'Spotify login',
			`dependent,menubar=no,toolbar=no,width=${innerWidth/2},height=${innerHeight/2}`
		);

		window.dbSpotifyAuthPromise = new Promise((resolve, reject) => {
			let interval;
			const cleanup = () => {
				window.dbSpotifyAuthPromise = null;
				window.removeEventListener('message', handleResponse, false);
				clearInterval(interval);
			};

			const checkState = () => {
				if (popup.closed) {
					cleanup();
					reject();
				}
			};

			const handleResponse = ({data: message}) => {
				cleanup();
				popup.close();

				switch (message.type) {
					case Messages.token:
						resolve(message.data);
						break;

					case Messages.error:
						reject(message.data);
						break;

					default:
						break;
				}
			};

			interval = setInterval(checkState, 100);
			window.addEventListener('message', handleResponse, false);
		});
	}

	return window.dbSpotifyAuthPromise;
};

export const hasToken = () =>
	!!ls.get(tokenKey)

export const getToken = async (refresh) => {
	if (refresh) {
		ls.remove(tokenKey);
	}

	const token = ls.get(tokenKey);

	if (token) {
		return token;
	}

	const freshToken = await requestToken();
	ls.set(tokenKey, freshToken);

	return freshToken;
};

const getApi = async (endpoint, query, options = {}) => {
	const token = await getToken();
	const queryString = query
		? `?${stringify(query)}`
		: '';

	set(options, 'headers.Authorization', `Bearer ${token}`);

	const url = `${apiUrl}${endpoint}${queryString}`;
	const response = await fetch(url, options);

	if (response.status === 401) {
		await getToken(true);
		return;
	}

	// API throttling
	if (response.status === 429) {
		const duration = parseInt(response.headers.get('Retry-After'), 10);
		await delay((duration * 1000) + 100);
		return await getApi(endpoint, query);
	}

	const {error, ...data} = await response.json();

	if (error) {
		const {status, message} = error;
		throw new Error(`Spotify API error: ${message} (${status})`);
	}

	if (!response.ok) {
		throw new Error();
	}

	return data;
};

export const fetchUserProfile = () =>
	getApi('/me');

export const fetchMatchingTrack = async (track, artist, album) => {
	const response = await getApi('/search', {
		q: `track:${track.title} artist:${artist.name} album:${album.title}`,
		type: 'track'
	});

	return get(response, 'tracks.items[0]');
};

export const fetchTrackFeatures = (id) =>
	getApi(`/audio-features/${id}`);

export const fetchManyTrackFeatures = async (ids) => {
	const response = await getApi('/audio-features', {
		ids: ids.join(',')
	});

	const features = get(response, 'audio_features', []);
	const indexed = keyBy(features, property('id'));

	return mapValues(indexed, (values) =>
		omit(values, [
			'analysis_url',
			'id',
			'track_href',
			'type',
			'uri'
		])
	);
};

export const fetchArtistGenres = async (id) => {
	const artist = await getApi(`/artists/${id}`);
	return get(artist, 'genres', []);
};

export const fetchAlbumGenres = async (id) => {
	const album = await getApi(`/albums/${id}`);
	return get(album, 'genres', []);
};

const mapTrackGenres = (artist, album) => {
	const genres = [
		...get(artist, 'genres', []),
		...get(album, 'genres', [])
	];

	if (!genres.length) {
		return {};
	}

	const inlineGenres = genres.join(' ');
	const attributes = mapValues(genreMappings, (pattern) => {
		const result = pattern.exec(inlineGenres);
		return result
			? result.length
			: 0;
	});

	const filtered = pickBy(attributes);
	const total = reduce(filtered, add, []);
	const amount = total
		? parseFloat((1 / total) * MaxValue).toFixed(3)
		: 0;

	return mapValues(filtered, (count) => count * amount);
};

const mapTrackFeatures = (track) => {
	const features = mapValues(featuresMappings, (feature) =>
		get(track, ['features', feature], false)
	);

	const validFeatures = pickBy(features, (value) =>
		(value !== false)
	);

	return mapValues(validFeatures, (value) =>
		parseFloat(value * MaxValue).toFixed(3)
	);
};

export const mapTrackAttributes = (track, artist, album) => ({
	...mapTrackGenres(artist, album),
	...mapTrackFeatures(track)
});

export const playTrack = (deviceId, id) =>
	getApi('/me/player/play', {
		device_id: deviceId
	}, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			uris: [
				`spotify:track:${id}`
			]
		})
	});

	export const trackUrl = (id) =>
		(id ? `https://open.spotify.com/track/${id}` : '');
