import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import elementResizeDetector from 'element-resize-detector';
import {debounce} from 'lodash-es';



export default (wideSize) => (WrappedComponent) => {
	class WithSize extends Component {

		constructor(props) {
			super(props);

			this.resizeDetector = elementResizeDetector();
			this.debouncedHandleResize = debounce(
				this.handleResize.bind(this),
				100
			);

			this.state = {
				isInitialized: false,
				isWide: true
			};
		}

		componentDidMount() {
			const parent = this.parentElement();

			this.handleResize(parent);
			this.resizeDetector.listenTo(
				parent,
				this.debouncedHandleResize
			);
		}

		componentWillUnmount() {
			this.resizeDetector.removeListener(
				this.parentElement(),
				this.debouncedHandleResize
			);
		}

		parentElement() {
			return findDOMNode(this).parentElement;
		}

		handleResize(element) {
			const isWide = element.offsetWidth > wideSize;

			if (!this.state.isInitialized || isWide !== this.state.isWide) {
				this.setState({
					isWide,
					isInitialized: true
				});
			}
		}

		render() {
			return this.state.isInitialized
				? <WrappedComponent {...this.props} isWide={this.state.isWide} />
				: <div />;
		}
	}

	return WithSize;
};
