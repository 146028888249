import React from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Players} from '../app/constants';
import {selectPlayer} from '../app/selectors';
import {setPlayer} from '../app/actions';
import Modal from './Modal';



const PlayerSelectorModal = ({isOpen, onSelect}) => (
	<Modal isOpen={isOpen}>
		<h1><FormattedMessage id="PlayerSelectorModal.title" /></h1>

		<div className="PlayerSelectorModal--playerList">
			<button
				className="Button PlayerSelectorModal--player"
				onClick={onSelect.bind(null, Players.deezer)}
			>
				<FontAwesomeIcon size="2x" icon={['db', 'deezer']} />
				<div>
					<strong>Deezer</strong><br />
					<FormattedMessage tagName="em" id="PlayerSelectorModal.deezerHint" />
				</div>
			</button>

			<button
				className="Button PlayerSelectorModal--player"
				onClick={onSelect.bind(null, Players.spotify)}
			>
				<FontAwesomeIcon size="2x" icon={['fab', 'spotify']} />
				<div>
					<strong>Spotify</strong><br />
					<FormattedMessage tagName="em" id="PlayerSelectorModal.spotifyHint" />
				</div>
			</button>

			<button
				className="Button PlayerSelectorModal--player"
				onClick={onSelect.bind(null, Players.youTube)}
			>
				<FontAwesomeIcon size="2x" icon={['fab', 'youtube']} />
				<div>
					<strong>Youtube</strong><br />
					<FormattedMessage tagName="em" id="PlayerSelectorModal.youTubeHint" />
				</div>
			</button>
		</div>
	</Modal>
);

export default connect(
	(state) => ({
		isOpen: !selectPlayer(state)
	}),
	(dispatch) => ({
		onSelect(player) {
			dispatch(setPlayer(player));
		}
	})
)(PlayerSelectorModal);
