import React from 'react';
import classNames from 'classnames';
import AttributeList from './AttributeList';
import PlayButton from './PlayButton';



const TrackItem = ({track, artist, album, isCurrent, isPlaying, isSelected, onPlay, onSelect}) => {
	const {title, attributes} = track;
	const className = classNames({
		TrackItem: true,
		'TrackItem--current': isCurrent || isSelected
	});

	return (
		<article className={className} onClick={onSelect}>
			<aside className="TrackItem-aside">
				<PlayButton
					isPlaying={isCurrent && isPlaying}
					onClick={onPlay}
				/>
			</aside>

			<div>
				<header className="TrackItem-header">
					<h3 className="TrackItem-title">{title}</h3>
					{artist && (
						<p className="TrackItem-artist">
							{' '}/ <span>{artist.name}</span>
						</p>
					)}
					<p className="TrackItem-album">
						{' '}/ <span>{album.title}</span>
					</p>
				</header>

				<AttributeList attributes={attributes} />
			</div>
		</article>
	);
};

export default TrackItem;
