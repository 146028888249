export const loadScript = (url, onLoad) => {
	const tag = document.createElement('script');
	tag.src = url;

	if (onLoad) {
		tag.onload = onLoad;
	}

	const firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};

export const units = (count = 1) =>
	count * parseInt(getComputedStyle(document.body).lineHeight, 10);
