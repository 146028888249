export const uid = 'data';

export const TOGGLE_EDIT_MODE = `${uid}/TOGGLE_EDIT_MODE`;
export const SET_DATA = `${uid}/SET_DATA`;
export const SET_TRACKS_ATTRIBUTES = `${uid}/SET_TRACKS_ATTRIBUTES`;
export const UPDATE_TRACK = `${uid}/UPDATE_TRACK`;
export const UPDATE_MANY_TRACKS = `${uid}/UPDATE_MANY_TRACKS`;
export const UPDATE_ARTIST = `${uid}/UPDATE_ARTIST`;
export const UPDATE_MANY_ARTISTS = `${uid}/UPDATE_MANY_ARTISTS`;
export const UPDATE_ALBUM = `${uid}/UPDATE_ALBUM`;
export const UPDATE_MANY_ALBUMS = `${uid}/UPDATE_MANY_ALBUMS`;

export const setData = (data) => ({
	type: SET_DATA,
	payload: data
});

export const setTracksAttributes = (attributes) => ({
	type: SET_TRACKS_ATTRIBUTES,
	payload: attributes
});

export const updateTrack = (id, values) => ({
	type: UPDATE_TRACK,
	payload: {id, values}
});

export const updateManyTracks = (tracks) => ({
	type: UPDATE_MANY_TRACKS,
	payload: tracks
});

export const updateArtist = (id, values) => ({
	type: UPDATE_ARTIST,
	payload: {id, values}
});

export const updateManyArtists = (artists) => ({
	type: UPDATE_MANY_ARTISTS,
	payload: artists
});

export const updateAlbum = (id, values) => ({
	type: UPDATE_ALBUM,
	payload: {id, values}
});

export const updateManyAlbums = (albums) => ({
	type: UPDATE_MANY_ALBUMS,
	payload: albums
});
