import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {keyBy, range, identity} from 'lodash-es';
import {Formik} from 'formik';
import {YoloValue, MaxValue} from '../data/constants';
import {allAttributes} from '../data/attributes';
import {selectIsEditing, selectSelectedTrackAttributes, selectSelectedTracksTitle} from '../admin/selectors';
import {setSelectedTracksAttributes} from './actions';
import RangeInputList from '../components/RangeInputList';



const TrackAttributesForm = ({isEditing, titles, editedAttributes, onSubmit, intl}) => (
	<div>
		<h1>
			{isEditing
				? `${titles.length} track(s)`
				: 'None'
			}
		</h1>

		<ul>
			{titles.map((title) => (
				<li>{title}</li>
			))}
		</ul>

		<Formik initialValues={editedAttributes} onSubmit={onSubmit} enableReinitialize>
			{({values, handleChange, handleSubmit}) => (
				<form onSubmit={handleSubmit}>
					<RangeInputList
						prefix="attribute"
						ids={allAttributes}
						values={values}
						min={YoloValue}
						max={MaxValue}
						disabled={!isEditing}
						options={{
							[YoloValue]: 'as is',
							...keyBy(range(0, MaxValue + 1), identity)
						}}
						handleChange={handleChange}
					/>

					<input
						className="Button"
						type="submit"
						value="Apply"
					/>
				</form>
			)}
		</Formik>
	</div>
);

export default connect(
	(state) => ({
		isEditing: selectIsEditing(state),
		titles: selectSelectedTracksTitle(state),
		editedAttributes: selectSelectedTrackAttributes(state)
	}),
	(dispatch) => ({
		onSubmit(attributes) {
			dispatch(setSelectedTracksAttributes(attributes));
		}
	})
)(injectIntl(TrackAttributesForm));
