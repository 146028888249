import React from 'react';
import {injectIntl} from 'react-intl';
import Player from '../player/Player';
import DeezerApiProvider from './DeezerApiProvider';
import DeezerPlayerController from './DeezerPlayerController';



const DeezerPlayer = ({intl}) => (
	<DeezerApiProvider
		renderLoading={() => (
			<Player
				isDisabled
				title={intl.formatMessage({
					id: 'DeezerPlayer.loading'
				})}
			/>
		)}
		render={(props) => (
			<DeezerPlayerController {...props} />
		)}
	/>
);

export default injectIntl(DeezerPlayer);
