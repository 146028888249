import React, {Component} from 'react';
import {loadScript} from '../api/dom';
import {hasToken, getToken} from './api';



export default class SpotifyApiProvider extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false
		};
	}

	componentDidMount() {
		if (window.dbSpotifyPlayer) {
			this.setLoaded();
			return;
		}

		if (window.onSpotifyWebPlaybackSDKReady) {
			return;
		}

		window.onSpotifyWebPlaybackSDKReady = () => {
			this.load();
		};

		loadScript('https://sdk.scdn.co/spotify-player.js');
	}

	load(refreshToken = false) {
		const player = new Spotify.Player({
			name: 'db player',
			getOAuthToken(cb) {
				getToken(refreshToken).then(cb).catch((a) => {
					console.log(a)
				});
			}
		});

		player.addListener('ready', () => {
			window.dbSpotifyPlayer = player;
			this.setLoaded();
		});

		player.addListener('authentication_error', () => {
			if (hasToken()) {
				this.load(true);
			}
		});

		player.connect();
	}

	setLoaded() {
		this.setState(() => ({
			isLoaded: true
		}));
	}

	render() {
		return this.state.isLoaded
			? this.props.render({
				player: window.dbSpotifyPlayer
			})
			: this.props.renderLoading();
	}
}
