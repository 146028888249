import React from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {keyBy, range, identity} from 'lodash-es';
import {Formik} from 'formik';
import {YoloValue, MaxValue} from '../data/constants';
import {globalAttributes, genreAttributes, influenceAttributes} from '../data/attributes';
import {filter} from '../app/actions';
import {selectFilters} from '../app/selectors';
import AdminTools from '../admin/AdminTools';
import AttributeFieldList from './AttributeFieldList';
import RangeInputList from './RangeInputList';



const FiltersForm = ({filters, onSubmit, intl}) => (
	<Formik initialValues={filters} onSubmit={onSubmit}>
		{({values, handleChange, handleSubmit}) => (
			<form className="FiltersForm" onSubmit={handleSubmit}>
				<div class="Form-field">
					<label htmlFor="query">
						{intl.formatMessage({
							id: 'FiltersForm.search.label'
						})}
					</label>

					<div className="Form-fieldInner">
						<input
							id="query"
							type="search"
							name="query"
							placeholder={intl.formatMessage({
								id: 'FiltersForm.search.placeholder'
							})}
							onChange={handleChange}
						/>
					</div>
				</div>

				<fieldset>
					<legend>
						<FormattedMessage id="FiltersForm.moods" />
					</legend>

					<div className="Form-fieldInner">
						<RangeInputList
							prefix="filter"
							namespace="attributes"
							ids={globalAttributes}
							values={values.attributes}
							min={YoloValue}
							max={MaxValue}
							options={{
								[YoloValue]: 'yolo',
								...keyBy(range(0, MaxValue + 1), identity)
							}}
							handleChange={handleChange}
						/>
					</div>
				</fieldset>

				<fieldset>
					<legend>
						<FormattedMessage id="FiltersForm.genres" />
					</legend>

					<AttributeFieldList
						namespace="attributes"
						isAdvanced={values.advanced}
						ids={genreAttributes}
						values={values.attributes}
						handleChange={handleChange}
					/>
				</fieldset>

				<fieldset>
					<legend>
						<FormattedMessage id="FiltersForm.influences" />
					</legend>

					<AttributeFieldList
						namespace="attributes"
						isAdvanced={values.advanced}
						ids={influenceAttributes}
						values={values.attributes}
						handleChange={handleChange}
					/>
				</fieldset>

				<fieldset>
					<legend>
						<FormattedMessage id="FiltersForm.options" />
					</legend>

					<div className="Form-fieldInner">
						<div className="Form-field Form-field--checkbox">
							<input
								id="randomized"
								name="randomized"
								type="checkbox"
								value={true}
								checked={values.randomized}
								onChange={handleChange}
							/>

							<label htmlFor="randomized">
								<FormattedMessage id="FiltersForm.randomized" />
							</label>
						</div>

						<div className="Form-field Form-field--checkbox">
							<input
								id="highlights"
								name="highlights"
								type="checkbox"
								value={true}
								checked={values.highlights}
								onChange={handleChange}
							/>

							<label htmlFor="highlights">
								<FormattedMessage id="FiltersForm.highlights" />
							</label>
						</div>

						<div className="Form-field Form-field--checkbox">
							<input
								id="unseen"
								name="unseen"
								type="checkbox"
								value={true}
								checked={values.unseen}
								onChange={handleChange}
							/>

							<label htmlFor="unseen">
								<FormattedMessage id="FiltersForm.unseen" />
							</label>
						</div>

						<div className="Form-field Form-field--checkbox">
							<input
								id="advanced"
								name="advanced"
								type="checkbox"
								value={true}
								checked={values.advanced}
								onChange={handleChange}
							/>

							<label htmlFor="advanced">
								<FormattedMessage id="FiltersForm.advanced" />
							</label>
						</div>
					</div>
				</fieldset>

				<input
					className="Button Button--bordered"
					type="submit"
					value={intl.formatMessage({
						id: 'FiltersForm.submit'
					})}
				/>

				{(process.env.NODE_ENV === 'production') ? null : (
					<AdminTools />
				)}
			</form>
		)}
	</Formik>
);

export default connect(
	(state) => ({
		filters: selectFilters(state)
	}),
	(dispatch) => ({
		onSubmit(filters) {
			dispatch(filter(filters));
		}
	})
)(injectIntl(FiltersForm));
