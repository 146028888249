import {SET_MESSAGE, CLEAR_MESSAGE} from './actions';



const initialState = {
	message: ''
};

export default (state = initialState, {type, payload}) => {
	switch (type) {
		case SET_MESSAGE:
			return {
				...state,
				message: payload
			};

		case CLEAR_MESSAGE:
			return {
				...state,
				message: ''
			};

		default:
			return state;
	}
};
