import jsonp from 'jsonp';



export const fetchVideoInfo = (id) =>
	new Promise((resolve, reject) => {
		const url = `https://noembed.com/embed?url=https%3A//youtube.com/watch%3Fv%3D${id}`;
		jsonp(url, {}, (error, data) => {
			if (error) {
				reject(error);
			} else {
				resolve(data);
			}
		});
	});
