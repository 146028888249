import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {map} from 'lodash-es';
import {selectArtists, selectAlbums} from '../data/selectors';
import {selectSelectedTrackIds} from '../admin/selectors';
import {selectPageTracks, selectIsPlaying, selectCurrentTrackId, selectCurrentPage} from '../app/selectors';
import {setTrackSelected} from '../admin/actions';
import {play} from '../app/actions';



const CtrlKey = 17;
const ShiftKey = 16;

class Tracks extends Component {

	constructor(props) {
		super(props);

		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.isCtrlPressed = false;
		this.isShiftPressed = false;
	}

	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown);
		window.addEventListener('keyup', this.handleKeyUp);
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown);
		window.removeEventListener('keyup', this.handleKeyUp);
	}

	componentWillReceiveProps({page}) {
		if (this.props.page !== page) {
			findDOMNode(this).scrollTop = 0;
		}
	}

	handleKeyDown({keyCode}) {
		if (keyCode === CtrlKey) {
			this.isCtrlPressed = true;
		}
		if (keyCode === ShiftKey) {
			this.isShiftPressed = true;
		}
	}

	handleKeyUp({keyCode}) {
		if (keyCode === CtrlKey) {
			this.isCtrlPressed = false;
		}
		if (keyCode === ShiftKey) {
			this.isShiftPressed = false;
		}
	}

	render() {
		const {
			Container,
			Item,
			isPlaying,
			currentTrackId,
			selectedTrackIds,
			tracks,
			artists,
			albums,
			onPlay,
			onSelect
		} = this.props;

		return (
			<div className="Tracks CustomScrollbar">
				<Container>
					{map(tracks, (track, id) => (
						<Item
							key={id}
							track={track}
							artist={artists[track.artistId]}
							album={albums[track.albumId]}
							isCurrent={id === currentTrackId}
							isPlaying={isPlaying}
							isSelected={selectedTrackIds.includes(id)}
							onPlay={onPlay.bind(this, id)}
							onSelect={() => {
								onSelect(
									id,
									!selectedTrackIds.includes(id),
									this.isCtrlPressed,
									this.isShiftPressed
								);
							}}
						/>
					))}
				</Container>
			</div>
		);
	}
}

export default compose(
	connect(
		(state) => ({
			...state,
			isPlaying: selectIsPlaying(state),
			currentTrackId: selectCurrentTrackId(state),
			selectedTrackIds: selectSelectedTrackIds(state),
			tracks: selectPageTracks(state),
			artists: selectArtists(state),
			albums: selectAlbums(state),
			page: selectCurrentPage(state)
		}),
		(dispatch) => ({
			onPlay(id) {
				dispatch(play(id));
			},
			onSelect(id, selected, isMultiple, isRange) {
				dispatch(setTrackSelected(id, selected, isMultiple, isRange));
			}
		})
	)
)(Tracks);
