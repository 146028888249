export const Attributes = {
	valence: 'valence',
	energy: 'energy',
	danceability: 'danceability',
	blues: 'blues',
	dub: 'dub',
	dubstep: 'dubstep',
	drumAndBass: 'drumAndBass',
	electronic: 'electronic',
	folk: 'folk',
	funk: 'funk',
	fusion: 'fusion',
	hipHop: 'hiphop',
	jazz: 'jazz',
	latin: 'latin',
	metal: 'metal',
	minimal: 'minimal',
	pop: 'pop',
	rnb: 'rnb',
	ragga: 'ragga',
	reggae: 'reggae',
	rock: 'rock',
	soul: 'soul',
	techno: 'techno',
	trance: 'trance',
	trap: 'trap',
	african: 'african',
	indian: 'indian',
	oriental: 'oriental',
	southAmerican: 'southAmerican'
};

export const globalAttributes = [
	Attributes.valence,
	Attributes.energy,
	Attributes.danceability
];

export const genreAttributes = [
	Attributes.blues,
	Attributes.dub,
	Attributes.dubstep,
	Attributes.drumAndBass,
	Attributes.electronic,
	Attributes.folk,
	Attributes.funk,
	Attributes.fusion,
	Attributes.hipHop,
	Attributes.jazz,
	Attributes.latin,
	Attributes.metal,
	Attributes.minimal,
	Attributes.pop,
	Attributes.rnb,
	Attributes.ragga,
	Attributes.reggae,
	Attributes.rock,
	Attributes.soul,
	Attributes.techno,
	Attributes.trance,
	Attributes.trap
];

export const influenceAttributes = [
	Attributes.african,
	Attributes.indian,
	Attributes.oriental,
	Attributes.southAmerican
];

export const allAttributes = [
	...globalAttributes,
	...genreAttributes,
	...influenceAttributes
];

export const genreMappings = {
	[Attributes.blues]: /blues/i,
	[Attributes.dub]: /dub\b/i,
	[Attributes.dubstep]: /darkstep|dubstep|filthstep|neurostep|zapstep/i,
	[Attributes.drumAndBass]: /breakbeat|drumAndBass|drum\sand\sbass/i,
	[Attributes.electronic]: /electro|edm|dance|vaporwave/i,
	[Attributes.folk]: /folk/i,
	[Attributes.funk]: /funk/i,
	[Attributes.fusion]: /fusion/i,
	// "ip\s?hop" matches "hip-hop" and "trip-hop"
	[Attributes.hipHop]: /deutschrap|ip\s?hop|lo-fi|\brap\b|scratch|turntablism|urban/i,
	[Attributes.jazz]: /bebop|big\sband|jazz|motown|swing/i,
	[Attributes.latin]: /latin/i,
	[Attributes.metal]: /deathcore|djent|mathcore|metal/i,
	[Attributes.minimal]: /lounge|house|minimal/i,
	[Attributes.pop]: /pop/i,
	[Attributes.rnb]: /r&b/i,
	[Attributes.ragga]: /dancehall|jungle|ragga/i,
	[Attributes.reggae]: /reggae|ska/i,
	[Attributes.rock]: /rock|grunge|punk|screamo|shoegaze/i,
	[Attributes.soul]: /soul/i,
	[Attributes.techno]: /techno/i,
	[Attributes.trance]: /didgeridoo|\bpsy|trance|tribe/i,
	[Attributes.trap]: /trap/i,
	[Attributes.african]: /africa|afro/i,
	[Attributes.indian]: /india|sitar/i,
	[Attributes.oriental]: /arab|lebanese|orient|oud|palestinian|\brai\b|turkish/i,
	[Attributes.southAmerican]: /baile|bossa|brazil|columbia|cuba|cumbia|ecuador|peru|rumba|samba/i
};

export const featuresMappings = {
	[Attributes.energy]: 'energy',
	[Attributes.valence]: 'valence',
	[Attributes.danceability]: 'danceability'
};
