import React, {Component} from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash-es';
import Player from '../player/Player';
import {selectTrackArtist} from '../data/selectors';
import {selectCurrentTrack, selectIsPlaying} from '../app/selectors';
import {playNext} from '../app/actions';
import {fetchVideoInfo} from './api';



const DefaultThumbnail = '';

export class YouTubePlayerController extends Component {

	constructor(props) {
		super(props);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.updateCurrentTime = this.updateCurrentTime.bind(this);
		this.handleSeek = this.handleSeek.bind(this);
		this.interval = null;
		this.state = {
			cuedTrackId: null,
			title: '',
			currentTime: 0,
			totalTime: 0
		};
	}

	componentDidMount() {
		this.props.player.addEventListener('onStateChange', this.handleStateChange);
		this.interval = setInterval(this.updateCurrentTime, 1000);

		this.loadCurrentTrack();
	}

	componentDidUpdate({
		isPlaying: wasPlaying,
		currentTrack: previousTrack
	}) {
		const {isPlaying, currentTrack, currentArtist, player} = this.props;

		if (currentTrack !== previousTrack) {
			this.loadTrack(
				currentTrack,
				currentArtist
			);

			// avoids playing the current track for a second
			// before changing
			return;
		}

		if (isPlaying !== wasPlaying) {
			isPlaying
				? player.playVideo()
				: player.pauseVideo();
		}
	}

	componentWillUnmount() {
		const {player} = this.props;

		if (this.interval) {
			clearInterval(this.interval);
		}

		player.removeEventListener('onStateChange', this.handleStateChange);
		player.pauseVideo();
	}

	loadCurrentTrack() {
		const {currentTrack, currentArtist} = this.props;

		if (currentTrack) {
			this.loadTrack(currentTrack, currentArtist);
		}
	}

	loadTrack(track, artist) {
		this.props.player.cuePlaylist({
			listType: 'search',
			list: [
				get(artist, 'name', ''),
				get(track, 'title', '')
			].join(' ')
		});

		this.setState(() => ({
			cuedTrackId: null,
			title: '',
			currentTime: 0,
			totalTime: 0
		}));
	}

	updateCurrentTime() {
		if (!this.props.isPlaying) {
			return;
		}

		const time = this.props.player.getCurrentTime();

		if (time !== undefined) {
			this.setState(() => ({
				currentTime: Math.round(time)
			}));
		}
	}

	handleStateChange(event) {
		switch (event.data) {
			case window.YT.PlayerState.PLAYING:
				this.handlePlayingState();
				break;
			case window.YT.PlayerState.CUED:
				this.handleCuedState();
				break;
			case window.YT.PlayerState.ENDED:
				this.handleEndedState();
				break;
		}
	}

	handlePlayingState() {
		const {player} = this.props;

		this.setState(() => ({
			totalTime: Math.round(player.getDuration())
		}));
	}

	handleCuedState() {
		const {player, onEnd} = this.props;
		const ids = player.getPlaylist();

		// skips to next song if no one is found
		if (!ids || !ids.length) {
			onEnd();
			return;
		}

		const id = ids[0];
		player.loadVideoById(id);

		this.setState(() => ({
			cuedTrackId: id
		}));

		fetchVideoInfo(id).then(({title}) => {
			this.setState(() => ({
				title
			}));
		});
	}

	handleEndedState() {
		this.props.onEnd();
	}

	handleSeek(seconds) {
		this.props.player.seekTo(seconds);
	}

	render() {
		const {cuedTrackId, title, currentTime, totalTime} = this.state;

		return (
			<Player
				isDisabled={!this.props.currentTrack}
				title={title}
				totalTime={totalTime}
				currentTime={currentTime}
				onSeek={this.handleSeek}
				url={
					cuedTrackId
						? `https://www.youtube.com/watch?v=${cuedTrackId}`
						: ''
				}
				thumbnailUrl={
					cuedTrackId
						? `https://i.ytimg.com/vi/${cuedTrackId}/hqdefault.jpg`
						: DefaultThumbnail
				}
			/>
		);
	}
}

export default connect(
	(state) => {
		const currentTrack = selectCurrentTrack(state);

		return {
			currentTrack,
			currentArtist: selectTrackArtist(get(currentTrack, 'id'))(state),
			isPlaying: selectIsPlaying(state)
		}
	},
	(dispatch) => ({
		onEnd() {
			dispatch(playNext())
		}
	})
)(YouTubePlayerController);
