import {uid} from './actions';
import reducer from './reducer';



export default () => ({
	id: uid,
	reducerMap: {
		[uid]: reducer
	}
});
