import React, {Component} from 'react';
import {baseLocation} from '../api/api';
import {loadScript} from '../api/dom';



const RootId = 'dz-root';
const ElementId = 'DeezerPlayer';

export default class DeezerApiProvider extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false
		};
	}

	componentDidMount() {
		if (document.getElementById(RootId)) {
			this.setLoaded();
			return;
		}

		const root = document.createElement('div');
		root.setAttribute('id', RootId);
		document.body.appendChild(root);

		const element = document.createElement('div');
		element.setAttribute('id', ElementId);
		element.classList.add(ElementId);
		element.style = 'position: absolute; left: -9999px';
		document.body.appendChild(element);

		loadScript('https://e-cdns-files.dzcdn.net/js/min/dz.js', () => {
			window.DZ.init({
				appId: process.env.DEEZER_APP_ID,
				channelUrl: `${baseLocation()}/channel.html`,
				player: {
					container: ElementId,
					onload: this.setLoaded.bind(this)
				}
			});
		});
	}

	setLoaded() {
		this.setState(() => ({
			isLoaded: true
		}));
	}

	render() {
		return this.state.isLoaded
			? this.props.render({
				player: window.DZ.player,
				events: window.DZ.Event
			})
			: this.props.renderLoading();
	}
}
