import React from 'react';
import {render} from 'react-dom';
import {IntlProvider} from 'react-intl';
import {Provider} from 'react-redux';
import {setAppElement} from 'react-modal';
import update, {extend} from 'immutability-helper';
import messages from './messages/en';
import store from './store';
import './icons';
import App from './components/App';



const element = document.getElementById('root');

setAppElement(element);

extend('$auto', (value, object) =>
	update(object || {}, value)
);

render((
	<IntlProvider locale="en-en" messages={messages}>
		<Provider store={store}>
			<App />
		</Provider>
	</IntlProvider>
), element);
