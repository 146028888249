import qs from 'qs';
import FlexSearch from 'flexsearch';
import {createBrowserHistory} from 'history';
import {eventChannel, takeEvery} from 'redux-saga';
import {all, take, takeLatest, select, put, call, setContext, getContext} from 'redux-saga/effects';
import {sortBy, pick, property, keys, get, uniq, forEach, clamp, map, groupBy, shuffle, pickBy} from 'lodash-es';
import {compare, fetchData} from '../api/api';
import {SortKeys, SortDirections} from './constants';
import {isAnyAttributeSet, attributesWeight} from './api';
import {YoloValue} from '../data/constants';
import {selectTracks, selectUnseenTracks, selectArtists, selectAlbums, selectSomeTracks} from '../data/selectors';
import {selectPageCount, selectTrackIds, selectSortKey, selectFilters, selectCurrentPage, selectSortDirection} from './selectors';
import {SET_DATA, UPDATE_MANY_TRACKS, setData} from '../data/actions';
import {setMeta} from '../metadata/actions';
import {
	SET_FILTERS,
	FILTER,
	SORT_TRACKS,
	setFilters,
	setSortedTracks,
	setFilteredTracks,
	setPage
} from './actions';



function* indexTracksSaga(tracks, artists, albums) {
	const flexSearch = yield getContext('flexSearch');

	forEach(tracks, ({id, title, timeAdded, artistId, albumId}) => {
		const artist = get(artists, [artistId, 'name'], '');
		const album = get(albums, [albumId, 'title'], '');

		flexSearch.add({
			id,
			// can't get the search to work properly on multiple
			// fields so using a concatenation of interesting data
			// @see https://github.com/nextapps-de/flexsearch/issues/70 (probably)
			text: `${title} ${artist} ${album}`,
			// the other fields are used by the sort algorithm
			[SortKeys.title]: title,
			[SortKeys.timeAdded]: timeAdded,
			[SortKeys.artist]: artist,
			[SortKeys.album]: album
		});
	});
}

function* indexUpdatedTracksSaga({payload: tracks}) {
	const ids = keys(tracks);
	const fullTracks = yield select(selectSomeTracks(ids))
	const trackIds = yield select(selectTrackIds);
	const artists = yield select(selectArtists);
	const albums = yield select(selectAlbums)

	yield call(indexTracksSaga, fullTracks, artists, albums);
	yield put(setFilteredTracks(uniq([
		...trackIds,
		...ids
	])));
}

function* sortTracksSaga({payload}) {
	const {key, direction} = payload;
	const flexSearch = yield getContext('flexSearch');
	const state = yield select();
	const trackIds = selectTrackIds(state);
	const sortKey = selectSortKey(state);
	const sortDirection = selectSortDirection(state);

	const invert = (
		sortKey
		&& sortDirection
		&& key === sortKey
		&& direction !== sortDirection
	);

	// dates as sorted in reverse order
	const factor = (key === SortKeys.timeAdded) ? -1 : 1;
	const sorted = invert
		? trackIds.reverse()
		: trackIds.sort((a, b) =>
			factor * compare(
				flexSearch.find(a)[key],
				flexSearch.find(b)[key]
			)
		);

	yield put(setSortedTracks(key, direction, sorted));
};

function* searchSaga(tracks, query) {
	const flexSearch = yield getContext('flexSearch');
	const matching = flexSearch.search(query);
	return pick(tracks, matching.map(property('id')));
}

function* filterTracksSaga() {
	const filters = yield select(selectFilters);
	const {
		query,
		attributes,
		randomized,
		highlights,
		unseen
	} = filters;

	const tracks = unseen
		? yield select(selectUnseenTracks)
		: yield select(selectTracks);

	const subset = highlights
		? pickBy(tracks, 'isAlbumHighlight')
		: tracks;

	const filtered = !!query
		? yield call(searchSaga, subset, query)
		: subset;

	const anySet = isAnyAttributeSet(attributes);
	let filteredIds;

	if (anySet) {
		const weighed = map(filtered, (track) => ({
			id: track.id,
			weight: anySet
				? attributesWeight(attributes, get(track, 'attributes', {}))
				: 0
		}));

		const sorted = sortBy(weighed, property('weight'));

		if (randomized) {
			const grouped = groupBy(sorted, property('weight'));
			const groupKeys = keys(grouped).map(parseFloat).sort(compare);

			filteredIds = groupKeys.reduce((all, key) => {
				const groupIds = grouped[key].map(property('id'));
				return all.concat(shuffle(groupIds));
			}, []);
		} else {
			filteredIds = sorted.map(property('id'));
		}
	} else {
		const ids = map(filtered, property('id'));

		filteredIds = randomized
			? shuffle(ids)
			: ids;
	}

	yield put(setFilteredTracks(filteredIds));

	const total = yield select(selectPageCount);
	const page = yield select(selectCurrentPage);
	const clampedPage = clamp(page, 0, total - 1);

	if (page !== clampedPage) {
		yield put(setPage(clampedPage));
	}
}

function* loadFromFileSaga(loadMeta) {
	const data = yield call(fetchData, 'data');
	yield put(setData(data));

	if (loadMeta) {
		const meta = yield call(fetchData, 'metadata');
		yield put(setMeta(meta));
	}
}

export function* loadSaga() {
	const method = loadFromFileSaga;
	const loadMeta = (process.env.NODE_ENV !== 'production');
	yield call(method, loadMeta);
}

export function* filterSaga({payload}) {
	const query = {};

	if (payload.query) {
		query.query = payload.query;
	}

	if (payload.randomized) {
		query.randomized = payload.randomized;
	}

	if (payload.highlights) {
		query.highlights = payload.highlights;
	}

	if (payload.unseen) {
		query.unseen = payload.unseen;
	}

	for (const [key, value] of Object.entries(payload.attributes)) {
		if (value !== YoloValue) {
			query[key] = value;
		}
	}

	yield call(changeLocationSaga, query);
}

export function* changeLocationSaga(query) {
	const history = yield getContext('history');
	history.push({
		search: qs.stringify(query)
	});
}

export function* handleLocationChangeSaga(location) {
	const query = qs.parse(location.search, {
		ignoreQueryPrefix: true
	});

	const filters = {
		query: '',
		attributes: {},
		randomized: false,
		highlights: false,
		unseen: false
	};

	for (const [key, value] of Object.entries(query)) {
		if (key === 'query') {
			filters.query = value;
			continue;
		}

		if (key === 'randomized') {
			filters.randomized = value;
			continue;
		}

		if (key === 'highlights') {
			filters.highlights = value;
			continue;
		}

		if (key === 'unseen') {
			filters.unseen = value;
			continue;
		}

		if (value !== YoloValue) {
			filters.attributes[key] = parseInt(value, 10);
		}
	}

	yield put(setFilters(filters));
}

export function* watchSetData() {
	const {payload} = yield take(SET_DATA);
	const {
		tracks,
		artists,
		albums
	} = payload;

	yield call(indexTracksSaga, tracks, artists, albums);
	yield put(setFilteredTracks(keys(tracks)));
	yield call(filterTracksSaga);
}

export function* watchUpdateManyTracks() {
	yield takeLatest(UPDATE_MANY_TRACKS, indexUpdatedTracksSaga);
}

export function* watchSortTracks() {
	yield takeLatest(SORT_TRACKS, sortTracksSaga);
}

export function* watchSetFilters() {
	yield takeLatest(SET_FILTERS, filterTracksSaga);
}

export function* watchFilter() {
	yield takeLatest(FILTER, filterSaga);
}

export function* watchLocationChange() {
	const history = yield getContext('history');
	const locationChannel = eventChannel((emit) => {
		return history.listen(emit);
	});

	yield call(handleLocationChangeSaga, history.location);
	yield takeEvery(locationChannel, handleLocationChangeSaga);
}

export function* rootSaga() {
	yield setContext({
		history: createBrowserHistory(),
		flexSearch: new FlexSearch('match', {
			doc: {
				id: 'id',
				field: 'text'
		  }
		})
	});

	yield all([
		loadSaga(),
		watchSetData(),
		watchUpdateManyTracks(),
		watchSortTracks(),
		watchSetFilters(),
		watchFilter(),
		watchLocationChange()
	]);
}
