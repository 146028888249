import {sum, some} from 'lodash-es';
import {YoloValue, MaxValue, isYoloValue, isNopeValue, isYepValue} from '../data/constants';



export const getPage = (items, pageSize, page) =>
	items.slice(page * pageSize, page * pageSize + pageSize);

export const isAnyAttributeSet = (attributes) =>
	some(attributes, (attribute) =>
		!isYoloValue(attribute)
	);

export const attributesWeight = (attributes, trackAttributes) => {
	const scores = [];
	let base = 0;

	for (const [id, value] of Object.entries(attributes)) {
		if (isYoloValue(value)) {
			continue;
		}

		const trackAttributeValue = (id in trackAttributes)
			? trackAttributes[id]
			: YoloValue;

		if (!isNopeValue(value) && isYoloValue(trackAttributeValue)) {
			scores.push(0);
			continue;
		}

		if (trackAttributeValue <= 0) {
			continue;
		}

		// boosts tracks that matches the maximum number of attributes
		if (isYepValue(value) && isYepValue(trackAttributeValue)) {
			base -= 100;
		}

		const distance = MaxValue - Math.abs(value - trackAttributeValue);
		const ratio = (distance / MaxValue) * 100;

		if (isNopeValue(value)) {
			base += 10000 - ratio;
		} else {
			scores.push(ratio);
		}
	}

	const score = scores.length
		? (sum(scores) / scores.length)
		: 0;

	return base + (100 - score);
}
