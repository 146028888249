import React from 'react';
import {keyBy, range, identity} from 'lodash-es';
import {NopeValue, MaxValue, YoloValue} from '../data/constants';
import AttributeInputList from './AttributeInputList';
import RangeInputList from './RangeInputList';



export default ({isAdvanced, ...props}) =>
	isAdvanced
		? (
			<RangeInputList
				{...props}
				prefix="filter"
				min={NopeValue}
				max={MaxValue}
				options={{
					[NopeValue]: 'nope',
					[YoloValue]: 'yolo',
					...keyBy(range(0, MaxValue + 1), identity)
				}}
			/>
		)
		: (
			<AttributeInputList {...props} />
		);
