import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';



export default ({isPlaying, onClick, ...props}) => (
	<button
		className="Button PlayButton"
		onClick={onClick}
		aria-pressed={(isPlaying)}
		{...props}
	>
		{(isPlaying)
			? <FontAwesomeIcon icon="pause" />
			: <FontAwesomeIcon icon="play" />
		}
	</button>
);
