import React from 'react';
import {connect} from 'react-redux';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {compose} from 'recompose';
import classNames from 'classnames';
import {property} from 'lodash-es';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {units} from '../api/dom';
import FiltersForm from './FiltersForm';
import Players from './Players';
import Pagination from './Pagination';
import Tracks from './Tracks';
import {selectIsEditMode} from '../admin/selectors';
import StatusBar from '../status/StatusBar';
import TrackAttributesForm from '../user/TrackAttributesForm';
import TrackTable from './TrackTable';
import TrackRow from './TrackRow';
import TrackList from './TrackList';
import TrackItem from './TrackItem';
import PlayerSelectorModal from './PlayerSelectorModal';
import withSize from './withSize';



const SearchPanel = () => (
	<div className="Panel Panel--search CustomScrollbar">
		<FiltersForm />
	</div>
);

const PlayersPanel = () => (
	<div className="Panel Panel--players">
		<Players />
	</div>
);

const TracksPanel = (props) => (
	<div className="Panel Panel--tracks">
		<Tracks {...props} />
		<Pagination />
	</div>
);

const EditorPanel = () => (
	<div className="Panel Panel--editor">
		<TrackAttributesForm />
	</div>
);

const CompactLayout = () => (
	<main className="Layout Layout--compact" role="main">
		<Tabs
			className="Tabs"
			selectedTabClassName="Tabs-tab--selected"
			selectedTabPanelClassName="Tabs-panel--selected"
			disabledTabClassName="Tabs-tab--disabled"
			defaultIndex={0}
			forceRenderTabPanel
		>
			<TabList className="Tabs-tabList ButtonList ButtonList--stretch">
				<Tab className="Tabs-tab Button">
					<FontAwesomeIcon icon="search" />
				</Tab>
				<Tab className="Tabs-tab Button">
					<FontAwesomeIcon icon="th-list" />
				</Tab>
			</TabList>

			<TabPanel className="Tabs-panel Tabs-panel--search">
				<SearchPanel />
			</TabPanel>

			<TabPanel className="Tabs-panel Tabs-panel--tracks">
				<TracksPanel Container={TrackList} Item={TrackItem} />
			</TabPanel>
		</Tabs>

		<PlayersPanel />
		<PlayerSelectorModal />
	</main>
);

const WideLayout = ({isEditMode}) => (
	<main className="Layout Layout--wide" role="main">
		<SearchPanel />
		<TracksPanel Container={TrackTable} Item={TrackRow} />
		{isEditMode
			? <EditorPanel />
			: null
		}
		<PlayersPanel />
		<StatusBar />
		<PlayerSelectorModal />
	</main>
);

const App = ({isWide, ...props}) => (
	isWide
		? <WideLayout {...props} />
		: <CompactLayout {...props} />
);

export default compose(
	withSize(units(45), property('parentElement')),
	connect((state) => ({
		isEditMode: selectIsEditMode(state)
	}))
)(App);
