export const uid = 'metadata';

export const SET_META = `${uid}/SET_META`;
export const UPDATE_TRACK_META = `${uid}/UPDATE_TRACK_META`;
export const UPDATE_MANY_TRACKS_META = `${uid}/UPDATE_MANY_TRACKS_META`;
export const UPDATE_ARTIST_META = `${uid}/UPDATE_ARTIST_META`;
export const UPDATE_MANY_ARTISTS_META = `${uid}/UPDATE_MANY_ARTISTS_META`;
export const UPDATE_ALBUM_META = `${uid}/UPDATE_ALBUM_META`;
export const UPDATE_MANY_ALBUMS_META = `${uid}/UPDATE_MANY_ALBUMS_META`;
export const FETCH_TRACK_META = `${uid}/FETCH_TRACK_META`;
export const FETCH_MANY_TRACKS_META = `${uid}/FETCH_MANY_TRACKS_META`;
export const DOWNLOAD_META = `${uid}/DOWNLOAD_META`;

export const setMeta = (meta) => ({
	type: SET_META,
	payload: meta
});

export const updateTrackMeta = (namespace, id, meta) => ({
	type: UPDATE_TRACK_META,
	payload: {namespace, id, meta}
});

export const updateManyTracksMeta = (namespace, meta) => ({
	type: UPDATE_MANY_TRACKS_META,
	payload: {namespace, meta}
});

export const updateArtistMeta = (namespace, id, meta) => ({
	type: UPDATE_ARTIST_META,
	payload: {namespace, id, meta}
});

export const updateManyArtistsMeta = (namespace, meta) => ({
	type: UPDATE_MANY_ARTISTS_META,
	payload: {namespace, meta}
});

export const updateAlbumMeta = (namespace, id, meta) => ({
	type: UPDATE_ALBUM_META,
	payload: {namespace, id, meta}
});

export const updateManyAlbumsMeta = (namespace, meta) => ({
	type: UPDATE_MANY_ALBUMS_META,
	payload: {namespace, meta}
});
