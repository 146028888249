import React from 'react';
import {FormattedMessage} from 'react-intl';
import {SortKeys} from '../app/constants';
import SortButton from './SortButton';



export default ({children}) => (
	<table className="TrackTable">
		<thead>
			<tr>
				<th><FormattedMessage id="TrackTable.play" /></th>
				<th>
					<FormattedMessage id="TrackTable.title" />{' '}
					<SortButton sortKey={SortKeys.title} /></th>
				<th>
					<FormattedMessage id="TrackTable.artist" />{' '}
					<SortButton sortKey={SortKeys.artist} />
				</th>
				<th>
					<FormattedMessage id="TrackTable.album" />{' '}
					<SortButton sortKey={SortKeys.album} />
				</th>
				<th><FormattedMessage id="TrackTable.attributes" /></th>
				<th>
					<FormattedMessage id="TrackTable.timeAdded" />{' '}
					<SortButton sortKey={SortKeys.timeAdded} />
				</th>
			</tr>
		</thead>

		<tbody>
			{children}
		</tbody>
	</table>
);
