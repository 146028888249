import {select, call} from 'redux-saga/effects';
import {saveFile} from '../api/api';
import {selectTimestamp} from '../data/selectors';
import {selectDownloadableData} from './selectors';
import {uid} from './actions';



export function* downloadDataSaga() {
	const data = yield select(selectDownloadableData);
	data.lastTimestamp = yield select(selectTimestamp);
	data.timestamp = Math.floor(Date.now() / 1000);

	yield call(saveFile, uid, data);
}
