import React from 'react';



export const DataList = ({children}) => (
	<table className="DataList">
		{children}
	</table>
);

export const DataListRow = ({label, children}) => (
	<tr>
		<th scope="row">{label}</th>
		<td>{children}</td>
	</tr>
);
