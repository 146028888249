import {watchSetSelectedTracksAttributes} from './sagas';



export const uid = 'user';

export default () => ({
	id: uid,
	sagas: [
		watchSetSelectedTracksAttributes
	]
});
