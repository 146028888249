import {select, put, call} from 'redux-saga/effects';
import {keys, pick, difference, size, mapValues, map, filter, pickBy} from 'lodash-es';
import {selectTracks, selectArtists, selectAlbums} from '../data/selectors';
import {updateManyTracks, updateManyArtists, updateManyAlbums} from '../data/actions';
import {selectAllTracksMeta} from '../metadata/selectors';
import {PageSize, aggregateData} from './api';
import {updateManyTracksMeta, updateManyArtistsMeta, updateManyAlbumsMeta} from '../metadata/actions';
import {setMessage} from '../status/actions';



const uid = 'deezer';

export function* fetchLatestDeezerTracks() {
	yield put(setMessage('Fetching tracks from Deezer likes...'));

	const tracks = yield select(selectTracks);
	const artists = yield select(selectArtists);
	const albums = yield select(selectAlbums);
	const deezerTracksMeta = yield select(selectAllTracksMeta(uid));

	const start = Math.floor(size(deezerTracksMeta) / PageSize) * PageSize;
	const data = yield call(aggregateData, `/user/${process.env.DEEZER_USER_ID}/tracks`, start);
	const {
		tracks: freshTracks,
		artists: freshArtists,
		albums: freshAlbums
	} = data;

	const newTrackIds = difference(keys(freshTracks), keys(tracks));
	const newTracks = pick(freshTracks, newTrackIds);

	yield put(updateManyTracks(newTracks));
	yield put(updateManyTracksMeta(uid, mapValues(newTracks, ({deezerId}) => ({
		id: deezerId
	}))));

	const newArtistIds = difference(keys(freshArtists), keys(artists));
	const newArtists = pick(freshArtists, newArtistIds);

	yield put(updateManyArtists(newArtists));
	yield put(updateManyArtistsMeta(uid, mapValues(newArtists, ({deezerId}) => ({
		id: deezerId
	}))));

	const newAlbumIds = difference(keys(freshAlbums), keys(albums));
	const newAlbums = pick(freshAlbums, newAlbumIds);

	yield put(updateManyAlbums(newAlbums));
	yield put(updateManyAlbumsMeta(uid, mapValues(newAlbums, ({deezerId}) => ({
		id: deezerId
	}))));

	yield put(setMessage('Successfully fetched tracks from Deezer likes.'));
}

export function* fetchAlbumHighlights() {
	yield put(setMessage('Fetching album highlights from Deezer...'));

	const data = yield call(aggregateData, `/playlist/${process.env.DEEZER_ALBUM_HIGHLIGHTS_PLAYLIST_ID}/tracks`);
	const {tracks} = data;
	const currentTracks = yield select(selectTracks);
	const currentTrackIds = map(currentTracks, 'deezerId');
	const existingTracks = pickBy(tracks, ({deezerId}) =>
		currentTrackIds.includes(deezerId)
	);

	const highlightData = mapValues(existingTracks, () => ({
		isAlbumHighlight: true
	}));

	yield put(updateManyTracks(highlightData));
	yield put(updateManyTracksMeta(uid, highlightData));

	yield put(setMessage('Successfully fetched album highlights from Deezer.'));
}
