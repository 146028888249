export const uid = 'admin';

export const SET_IS_REFRESHING = `${uid}/SET_IS_REFRESHING`;
export const SET_SELECTED_TRACK_IDS = `${uid}/SET_SELECTED_TRACK_IDS`;
export const SET_LAST_SELECTED_TRACK_ID = `${uid}/SET_LAST_SELECTED_TRACK_ID`;
export const TOGGLE_EDIT_MODE = `${uid}/TOGGLE_EDIT_MODE`;
export const SET_TRACK_SELECTED = `${uid}/SET_TRACK_SELECTED`;
export const FETCH_ALBUM_HIGHLIGHTS = `${uid}/FETCH_ALBUM_HIGHLIGHTS`;
export const FETCH_LATEST_TRACKS = `${uid}/FETCH_LATEST_TRACKS`;
export const FETCH_SELECTED_TRACKS_META = `${uid}/FETCH_SELECTED_TRACKS_META`;
export const AGGREGATE_TRACKS_META = `${uid}/AGGREGATE_TRACKS_META`;
export const SAVE_ALL = `${uid}/SAVE_ALL`;

export const setIsRefreshing = (refreshing) => ({
	type: SET_IS_REFRESHING,
	payload: refreshing
});

export const setSelectedTrackIds = (ids) => ({
	type: SET_SELECTED_TRACK_IDS,
	payload: ids
});

export const setLastSelectedTrackId = (id) => ({
	type: SET_LAST_SELECTED_TRACK_ID,
	payload: id
});

export const toggleEditMode = () => ({
	type: TOGGLE_EDIT_MODE,
	payload: {}
});

export const setTrackSelected = (id, selected, isMultiple, isRange) => ({
	type: SET_TRACK_SELECTED,
	payload: {id, selected, isMultiple, isRange}
});

export const fetchAlbumHighlights = () => ({
	type: FETCH_ALBUM_HIGHLIGHTS,
	payload: {}
});

export const fetchLatestTracks = () => ({
	type: FETCH_LATEST_TRACKS,
	payload: {}
});

export const fetchSelectedTracksMeta = () => ({
	type: FETCH_SELECTED_TRACKS_META,
	payload: {}
});

export const aggregateTracksMeta = () => ({
	type: AGGREGATE_TRACKS_META,
	payload: {}
});

export const saveAll = () => ({
	type: SAVE_ALL,
	payload: {}
});
