import {library} from '@fortawesome/fontawesome-svg-core';
import {faSort, faSortUp, faSortDown, faSyncAlt, faPen, faSave, faPlay, faPause, faDatabase, faTags, faStar, faSearch, faThList, faStepBackward, faStepForward, faFastBackward, faFastForward, faCog} from '@fortawesome/free-solid-svg-icons';
import {faYoutube, faSpotify} from '@fortawesome/free-brands-svg-icons';
import dbDeezer from './deezer';

library.add(faSort);
library.add(faSortUp);
library.add(faSortDown);
library.add(faSyncAlt);
library.add(faPen);
library.add(faSave);
library.add(faPlay);
library.add(faPause);
library.add(faDatabase);
library.add(faTags);
library.add(faStar);
library.add(faSearch);
library.add(faThList);
library.add(faYoutube);
library.add(faSpotify);
library.add(faStepBackward);
library.add(faStepForward);
library.add(faFastBackward);
library.add(faFastForward);
library.add(faCog);
library.add(dbDeezer);
