import React, {Component} from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash-es';
import {selectIsPlaying} from '../app/selectors';
import {setIsPlaying, playNext} from '../app/actions';
import {selectCurrentTrackSpotifyId} from './selectors';
import {playTrack, trackUrl} from './api';
import Player from '../player/Player';



const DefaultThumbnail = '';

export class SpotifyPlayerController extends Component {

	constructor(props) {
		super(props);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.updateCurrentTime = this.updateCurrentTime.bind(this);
		this.handleSeek = this.handleSeek.bind(this);
		this.state = {
			title: '',
			thumbnailUrl: DefaultThumbnail,
			currentTime: 0,
			totalTime: 0
		};
	}

	//
	componentDidMount() {
		const {player} = this.props;

		player.addListener('player_state_changed', this.handleStateChange);

		this.interval = setInterval(this.updateCurrentTime, 1000);
		this.loadCurrentTrack();
	}

	componentDidUpdate({
		isPlaying: wasPlaying,
		currentTrackId: previousTrackId
	}) {
		const {isPlaying, currentTrackId, player} = this.props;

		if (currentTrackId !== previousTrackId) {
			this.loadCurrentTrack();

			// avoids playing the current track for a second
			// before changing
			return;
		}

		if (isPlaying !== wasPlaying) {
			isPlaying
				? player.resume()
				: player.pause();
		}
	}

	componentWillUnmount() {
		const {player} = this.props;

		if (this.interval) {
			clearInterval(this.interval);
		}

		player.removeListener('player_state_changed', this.handleStateChange);
		player.pause();
	}

	loadCurrentTrack() {
		if (this.props.currentTrackId) {
			this.loadTrack(this.props.currentTrackId);
		}
	}

	loadTrack(id) {
		playTrack(this.props.player._options.id, id);

		this.setState(() => ({
			title: '',
			thumbnailUrl: DefaultThumbnail,
			currentTime: 0,
			totalTime: 0
		}));
	}

	updateCurrentTime() {
		if (!this.props.isPlaying) {
			return;
		}

		this.props.player.getCurrentState().then(({position}) => {
			this.setState(() => ({
				currentTime: Math.round(position) / 1000
			}));
		});
	}

	handleStateChange({
		position,
		duration,
		track_window: {
			current_track: track
		}
	}) {
		const currentTime = Math.round(position / 1000);
		const totalTime = Math.round(duration / 1000);

		if (currentTime >= totalTime) {
			this.props.onEnd();
			return;
		}

		this.setState(() => ({
			title: get(track, ['name'], ''),
			thumbnailUrl: get(track, ['album', 'images', 0, 'url'], ''),
			currentTime,
			totalTime
		}));
	}

	handleSeek(seconds) {
		if (this.state.totalTime) {
			this.props.player.seek(seconds * 1000);
		}
	}

	render() {
		const {currentTrackId} = this.props;
		const {title, thumbnailUrl, totalTime, currentTime} = this.state;

		return (
			<Player
				isDisabled={!currentTrackId}
				title={title}
				thumbnailUrl={thumbnailUrl}
				totalTime={totalTime}
				currentTime={currentTime}
				onSeek={this.handleSeek}
				url={trackUrl(currentTrackId)}
			/>
		);
	}
}

export default connect(
	(state) => ({
		isPlaying: selectIsPlaying(state),
		currentTrackId: selectCurrentTrackSpotifyId(state)
	}),
	(dispatch) => ({
		onEnd() {
			dispatch(playNext());
		},
		onPlay(playing) {
			dispatch(setIsPlaying(playing));
		}
	})
)(SpotifyPlayerController);
